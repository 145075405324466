<template>
    <router-view />
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
    }
});
</script>
