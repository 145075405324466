/*
*	Class WaveformOctree
*
*	This class creates a Spatial partitioning system to devide the waveform into small sections,
*	WaveformOctree is a class to optimize rendering of large data sets 
*	
*	Usage : WaveformOctreeNode <-- A waveform section to be rendered when a node is within the current viewport.
*
*/
import {  WaveformOctreeNode  } from './WaveformOctreeNode.js';
import {  WaveformPlayer  } from './WaveformPlayer.js';

import {  CuePoint  } from './CuePoint.js';

import {  CuePointRenderer  } from './CuePointRenderer.js';

import * as momentTemp from '../util/moment.js';
const moment = momentTemp["default"];


export class WaveformOctree 
{
    constructor() 
    {
        if (!WaveformOctree._instance) {
            WaveformOctree._instance = this;
        }
        
        /*
        *	Type  	 : Array()
        *   Comment  : The waveform octree nodes used for spatial partitioning.
        */
        this.OctreeNodes  = [];

        /*
        *	Type 	 : integer
        * 	Comment  : The total waveform data length.
        */
        this.total_length  = 0;

        /*
        *	Type 	 : integer
        * 	Comment  : The total waveform nodes
        */
        this.total_nodes  = 0;

        /*
        *	Type   	 : String
        * 	Comment  : The waveform_txt that holds the waveform data.
        */
        this.waveform_txt  = undefined;

        /*
        *	Type 	 : HTMLCanvas
        *	Comment  : The zoomed waveform rendering canvas
        */
        this.waveformCanvas = null;

        this.HitStatistics = null;

        /*
        * 	Type 	 : integer
        *	Comment  : The virtual canvas width / the total panned width of the waveform.
        */
        this.irtualWaveformCanvasWidth = 0;

        this.anvasLoaded = false;
        this.anvasTimeLoaded = false;

        this.secondXWidth = 0;

        this.t = 0;
        this.previousTime = 0;

        this.updateMsLabel = 0;

        this.frameMs = 0;

        this.section_data_count = 0;

        this.offscreenCanvas    = null;

        this.isInitialized = false;

        this.normalizationFactor = 0.025;

        this.nodeIndexesToDraw = [];

      return WaveformOctree._instance;
    }
   
    static getInstance() {
      return this._instance;
    }

    /* Recheck if waveform data is done being created */
    RecheckDataExists()
    {
        //alert("set");
        setTimeout(function(){
            //alert("Timeout called");
            $.post( "/ajax-check-if-generate-wave-form-data-done",
                {
                    waveform_txt: WaveformOctree.waveform_txt
                })
                .done(function( data )
                {
                    if(data.exists == "true")
                        this.InitOctree();
                    else
                    this.RecheckDataExists();

                    data.progress = Math.abs(data.progress);

                    $('.progress-bar').css({"width":+data.progress+"%"});
                    $('.progress-bar').attr("aria-valuenow", data.progress);
                    $('.sr-only').html(data.progress+"% Complete");
                }.bind(this));

        },5000)
    }

    DestroyOctree()
    {
        clearInterval(WaveformOctree.refreshIntervalId);
        for(var i = 0; i < WaveformOctree.OctreeNodes.length; i++)
        {
            WaveformOctree.OctreeNodes[i].nodeimage = null;
            WaveformOctree.OctreeNodes[i].preRendered = false;
            delete WaveformOctree.OctreeNodes[i];
        }
    }

        
    async GetMetadata()
    {
        return new Promise((resolve) => 
            {            
                var oReq = new XMLHttpRequest();
                oReq.open("GET", "https://service-djsets.s3.eu-west-1.amazonaws.com/Club_air_test/waveforms/Club_Air-Main-20240615-224527.txt", true);
                oReq.responseType = "json";

                oReq.onload = (e) => 
                {
                    if (oReq.readyState === 4) 
                    {
                        if (oReq.status === 200) 
                        {
                            var response = oReq.response;
                            // response = (JSON).parse(response);
                            resolve(response);
                        } 
                        else 
                        {
                            console.error(oReq.statusText);
                        }
                    }
                };


                oReq.send();      
            
                
            });           
    }

    async GetBinarySection(channel, step)
    {
        return new Promise((resolve) => 
        {            
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "https://service-djsets.s3.eu-west-1.amazonaws.com/Club_air_test/waveforms/Club_Air-Main-20240615-224527.txt."+channel+step, true);
            oReq.responseType = "arraybuffer";

            oReq.onload = (e) => 
            {
                if (oReq.readyState === 4) 
                {
                    if (oReq.status === 200) 
                    {
                        var byteArray = new Uint8Array(oReq.response);
                        // response = (JSON).parse(response);
                        resolve(byteArray);
                    } 
                    else 
                    {
                        console.error(oReq.statusText);
                    }
                }
            };


            oReq.send();     
        
        });            
    }

    /*
        Type    : function ()
        Comment : Initializes the Octree
    */
    async InitOctree(vue_instance, waveform_txt)
    {
        this.cuePointRenderer = new CuePointRenderer();

        this.offscreenCanvas = document.createElement("canvas");
        this.vue_instance = vue_instance;
        this.normalizationFactor = 0.25;

        this.ZoomFactor = 1;
        /* Initialize OctreeNodes Array() */
        this.OctreeNodes 	  = [];

        /* Fetch a instance of the canvas */
        this.waveformCanvas = $('#waveform-zoomed');

        this.channel = this.vue_instance.refs.waveform.selectedWavefromChannel.value;

        /*
        
        $('.channel_picker').unbind();
        $('.channel_picker').on('click',function()
        {
            $('.channel_picker.active').removeClass("active");
            $(this).addClass("active");
            WaveformOctree.DestroyOctree();
            WaveformOctree.InitOctree(vue_instance, WaveformOctree.waveform_txt);
        });

        */

        /* Fetch the waveform file to be used */
        this.waveform_txt   = waveform_txt;//$('#audio_file').attr('data-txt');

        var data_main = await this.GetMetadata();
                            
        const waveform_data = await this.GetBinarySection("mono",0);

        console.log(waveform_data);
        // data = (JSON).parse(data);

        if(typeof data_main.doesent_exist != "undefined") 
        {
            $('.loading_waveform').show();
            this.waveformCanvas.hide();
            $('#waveform-zoomed').hide();

            this.RecheckDataExists();
            RecreateWaveform('data');
        }
        else
        {
            $('.loading_waveform').hide();
            this.waveformCanvas.show();
            $('#waveform-zoomed').show();
        }

        if(typeof waveform_data == "undefined")
        {
            console.log(waveform_data);
            return;
        }

        this.section_data_count = waveform_data.length;
        this.total_length       = data_main.total_length_sampled;
        this.total_nodes        = this.total_length / this.section_data_count;
        var virtualLength       = (this.section_data_count * this.total_nodes)  * this.ZoomFactor;
        this.secondXWidth       = virtualLength / WaveformPlayer.audio.duration;
        this.sectionsSeconds    = (this.section_data_count) / this.secondXWidth;

       
        console.log( "total nodes : " + this.total_nodes );

        var cue_point_count_start_node = 0;

        for(var i = 0; i < Math.ceil(this.total_nodes); i++)
        {
            var node = new WaveformOctreeNode(this);            
            node.start_second  = ( (i) * this.sectionsSeconds );

            var node_width = node.start_second  * this.secondXWidth;

            node.waveform_txt  = this.waveform_txt;
            node.width 		   = this.sectionsSeconds * this.secondXWidth ;
            node.x  		   = node.start_second  * this.secondXWidth;
            node.start_x  	   = node.start_second  * this.secondXWidth;
            
            node.node_index    = i;


             

            if(i == 0)
            {
                node.isLoaded = true;
                node.waveform_data = waveform_data;
            }
            //else


            this.OctreeNodes.push(node);
            this.OctreeNodes[i].cue_points = [];
            // this.InitCuepoints(vue_instance,i,cue_point_count_start_node);
            // cue_point_count_start_node += this.OctreeNodes[i].cue_points.length;
        }


        this.isInitialized = true;            

        this.refreshIntervalId = setInterval(function()
        {
            this.CheckNodesInView(false , 1);
        }.bind(this),500);
    }
   
    CheckNodesInView(slowPc , elapsed)
    {
        var c = document.getElementById("waveform-zoomed");
        var startPosition =  $(c).width() /2;
        
        this.nodeIndexesToDraw = [];            

        $.each(this.OctreeNodes, function (index)
        {
            var startDataOffset    =  (( WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration)  * this.total_length * this.ZoomFactor) ;
            if(WaveformPlayer.newPlayCurrentTime != null)
                startDataOffset    =  (( WaveformPlayer.newPlayCurrentTime / WaveformPlayer.audio.duration) * this.total_length * this.ZoomFactor) ;

            var playPos            = startDataOffset;            

            if( this.IsNodeInView(playPos  - startPosition ,index))
            {
                if( typeof this.OctreeNodes[index].waveform_data != 'undefined')
                {
                    this.nodeIndexesToDraw.push(index);                      
                }
            }
            else
            {
                this.OctreeNodes[index].nodeimage = null;
                this.OctreeNodes[index].preRendered = false;
            }
        }.bind(this));
    }

    DrawCueOverlayFingerprinter(canvasContext, startDataOffset, node_index)
    {
        if(WaveformPlayer.FingerprinterActive)
        {
            var widthT = WaveformPlayer.audio.currentTime;

            if(WaveformPlayer.fingerprint_stop_position != 0)
                widthT = WaveformPlayer.fingerprint_stop_position;

            var cuepoint  = new CuePoint;
            cuepoint.index = 0;
            cuepoint.x    = (WaveformPlayer.fingerprint_start_position *this.secondXWidth) - (node_index * (300 * this.secondXWidth)) ;// + 525;
            cuepoint.y    = 0;
            cuepoint.w    = (widthT - WaveformPlayer.fingerprint_start_position) * this.secondXWidth;
            cuepoint.h    = 150;
            cuepoint.no_name = true;

            // var startDataOffset    =  ( WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) * WaveformOctree.virtualWaveformCanvasWidth;
            cuepoint.Draw( this.vue_instance, context,startDataOffset,-1);
        }
        else
            WaveformPlayer.fingerprint_stop_position = 0;

    }

    DrawWaveformOctree(slowPc , elapsed)
    {
        var maximumFrameTime = 1000/40; // 30 FPS
        this.t = performance.now();
        WaveformPlayer.elapsed  = this.t - this.previousTime;
        this.previousTime = this.t;

        // console.log("Slow Pc :" + slowPc + "<-?");

        if(!isNaN(slowPc) && slowPc != true)
            slowPc = false;

        if( !this.isInitialized )
            return;

        slowPc = false;

        var c = document.getElementById("waveform-zoomed");
        // alert( $(c).width() );
        c.width = $(c).width() ;
        var ctx = c.getContext("2d");
        ctx.fillStyle = "transparent";
        ctx.fillRect( 0, 0, $(c).width() , 150 );

        this.canvasLoaded = true;
        

        if( ! this.canvasTimeLoaded )
        {
            this.canvasTimeLoaded = true;
        }

        var c = document.getElementById("waveform-zoomed");
        var startPosition =  $(c).width() /2;

        var startDataOffset    =  (( WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) * this.total_length) * this.ZoomFactor;
        if(WaveformPlayer.newPlayCurrentTime != null)
            startDataOffset    =  (( WaveformPlayer.newPlayCurrentTime / WaveformPlayer.audio.duration) * this.total_length) * this.ZoomFactor;

        var playPos = startDataOffset;
        
        console.log(this.nodeIndexesToDraw);

        for (let i = 0; i < this.nodeIndexesToDraw.length; i++) 
        {           
            var index = this.nodeIndexesToDraw[i];

            

            // this.section_data_count = 13231;

            //this.OctreeNodes[index].x     = startDataOffset - ( (this.section_data_count +1) * index );
            //this.OctreeNodes[index].width = (this.section_data_count +1)* this.ZoomFactor;

            if(index != 0)
            {
                //   playPos -= ((this.section_data_count) * index);
                //   startPosition =  $(c).width();
            }

            let node_offset = (( this.OctreeNodes[index].x) ) + startPosition;

            if( typeof this.OctreeNodes[index].waveform_data != 'undefined')
            {
                // console.log(startDataOffset -startPosition);
                this.OctreeNodes[index].DrawPreRenderedNode( this.offscreenCanvas, this.offscreenCanvas.getContext('2d'), playPos - node_offset ,index,slowPc);

                //var viewCanvas = document.getElementById('waveform-zoomed');
                //var viewCtx    = viewCanvas.getContext('2d');
                //this.DrawCueOverlayFingerprinter(viewCtx ,startDataOffset -startPosition, index);
            }
            // }
            // else
            // {
            //     WaveformOctree.OctreeNodes[index].nodeimage = null;
            //     WaveformOctree.OctreeNodes[index].preRendered = false;
            // }
        }

        
        var c = document.getElementById("waveform-zoomed");
        //c.width = $(c).width() ;

        // Play line
        var context = c.getContext("2d");
        context.beginPath();
        context.lineWidth = 1;
        context.strokeStyle = "#3ecece";

        context.moveTo(c.width /2 , 0);
        context.lineTo(c.width /2 , c.height);
        context.stroke();
        context.closePath();
        

        var canvastime = document.getElementById('waveform-zoomed-time-layer');
        if(canvastime.getContext)
        {
            var ctx = canvastime.getContext('2d');
            canvastime.width = $("#waveform-zoomed-time-layer").width();

            if(!this.canvasTimeLoaded)
            {
                ctx.clearRect(0,0, canvastime.width, canvastime.height);
                this.canvasTimeLoaded = true;
            }
            
            var x = (canvastime.width /2) +2;

            if(parseInt(this.updateMsLabel) >= 500)
            {
                this.frameMs = parseInt(WaveformPlayer.elapsed) + "ms";
                this.updateMsLabel = 0;
            }

            ctx.fillStyle = "#adfdad";
            ctx.font = "bold 16px Arial";

            ctx.fillText(this.frameMs, 300, 0);

            if(!isNaN(parseInt(WaveformPlayer.elapsed))) {
                this.updateMsLabel += parseInt(WaveformPlayer.elapsed);
            }

            this.cuePointRenderer.Draw(playPos, this.nodeIndexesToDraw,this.OctreeNodes,canvastime,ctx);
        }

        
    }

    IsNodeInView(startDataOffset,index)
    {
        
        var canvas_width = $('#waveform-zoomed').width();
        /*
            if (Is Node within current viewport)
                return true
        */

        var offset = startDataOffset;

        // WaveformOctree.section_data_count = 13231;

        var x 	  = offset -  ( (this.section_data_count) * index );
        var width = (this.section_data_count);

        // console.log("this.section_data_count : " + WaveformOctree.section_data_count ) ;
        // console.log("canvas.width "  + canvas.width);
        // console.log("startDataOffset "  + startDataOffset);
        // console.log("index "  + index);
        // if(index < 6)
        // $('.view_array').append( startDataOffset +  '  >  ' + ((13231 * index) - 525)  + '<br/>');


        if( ( (startDataOffset + (canvas_width*2) ) >  ((this.section_data_count) * index) - (canvas_width*2) )   &&  ( (startDataOffset - (canvas_width*2)) < ( ((this.section_data_count) * index) - (canvas_width*2) )  + (this.section_data_count) ) )
        {
            console.log("Node in view : " + index);
            if(!this.OctreeNodes[index].isLoaded)
            {
                this.loadNodeData(index);
                this.OctreeNodes[index].isLoaded = true;
            }
            // if(index == 0)
            return true;
            //WaveformOctree.OctreeNodes[1].FillFirstSectionData();
        }

        return false;
    }
        
    async loadNodeData (index)
    {
        if(!this.OctreeNodes[index].isLoaded  )
        {
            const waveform_data = await this.GetBinarySection(this.vue_instance.refs.waveform.selectedWavefromChannel.value,index);
            if(typeof waveform_data != 'undefined')
            {
                this.OctreeNodes[index].waveform_data = [];
                this.OctreeNodes[index].waveform_data = waveform_data;

                //data.data = null;
                this.OctreeNodes[index].isLoaded = true;
            }

            this.vue_instance.isLoading = false;
        }
    
    }


    // Based on the start second of the track recognition, insert the cuepoint in the QuadtreeNode representing the section that the start second of the track recognition will fall into.
    InitCuepoints(vue_instance,i,cue_point_start_index_for_node)
    {            
        var rows = vue_instance.tracks;// $(".p-datatable-tbody > tr.p-selectable-row");

        console.log("InitCuepoints : " + rows.length);
        //alert(rows);

        //console.log("init cuepoints ");        
        this.OctreeNodes[i].InitNode();

        var cuepoints = [];

        var recording_start = moment($(".entrys").attr("data-recording-start-timestamp"),'YY-MM-DD HH:mm:ss');
        //alert(recording_start.format()); 

        $.each(rows, function (index)
        {           

            var duration_start = moment.duration( moment(rows[index].start_time,'YY-MM-DD HH:mm:ss').diff(recording_start));
            var duration_end = moment.duration( moment(rows[index].end_time,'YY-MM-DD HH:mm:ss').diff(recording_start));


            var start_second = duration_start.asSeconds();
            var end_second  = duration_end.asSeconds();

            var no_name      = $(rows[index]).attr('data-no-name');
            var title        = rows[index].track_title;

            //  alert(start_second);
            // 	console.log(index + ' start : '+start_second);
            //	console.log(index + ' end   : '+end_second);
            //  console.log('node start : ' + WaveformOctree.OctreeNodes[i].x);


            var cuePoint  = new CuePoint;
            cuePoint.index = index;
            cuePoint.x    = (start_second *this.secondXWidth)  ;// + 525;
            cuePoint.end_x    = (end_second *this.secondXWidth)  ;// + 525;
            cuePoint.y    = 0;
            cuePoint.w    = (end_second - start_second) *this.secondXWidth;
            cuePoint.h    = 150;
            cuePoint.no_name = (no_name == 'true' ? true : false);
            cuePoint.track_title = title;
            cuePoint.alreaady_duplicated_other_node_cuepoint = false;

            var rect               		   = new CuePoint;
            cuePoint.left_selectionHandle  = rect;

            if( start_second >= this.OctreeNodes[i].start_second && start_second < this.OctreeNodes[i].start_second + this.sectionsSeconds)
            {
                cuepoints.push(cuePoint);
                //alert("Add Cuepoint to node " + i);
            }
            //WaveformOctree.OctreeNodes[0].cue_points.push(CuePoint);
            // }
        }.bind(this));

        console.log( JSON.stringify(cuepoints) );
        this.OctreeNodes[i].setCuepoints(cuepoints,cue_point_start_index_for_node);

        // console.log('Cue point : ! ' + WaveformOctree.OctreeNodes[i].cue_points[0]  );
    }

  }
