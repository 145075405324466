export default [
    {
        path: '/auth/login-callback',
        component: () => import('./components/LoginCallback.vue'),
        meta: { public: true },
    },
    {
        path: '/auth/logout-callback',
        component: () => import('./components/LogoutCallback.vue'),
        meta: { public: true },
    },
    {
        path: '/login',
        component: () => import('./page-modules/Login.vue'),
        meta: { public: true },
    },
];
