export default [
    {
        path: '/playlists/view/:version_uuid',
        component: () => import('../../../dmt/layouts/Dashboard.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/View.vue'),
                meta: { action: 'dmt:playlists:view:view' },
            }
        ],
    }
];
