import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import VueAxios from 'vue-axios';

import App from './App.vue';
import Auth from './modules/auth/plugins/Auth';
import router from './router';

const app = createApp(App);

import primevue_loader from './primevue_loader';
primevue_loader(app);

import Waveform from './modules/shared/page-modules/playlist/components/Waveform.vue';
app.component('Waveform', Waveform);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            app.config.globalProperties.auth.login();
        }
        if (error.response.status === 403) {
            router.push({ name: 'Forbidden' });
        } else if (error.response.status === 404) {
            router.push({ name: 'NotFound' });
        }

        return Promise.reject(error);
    },
);

app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);

app.use(Auth);
app.provide('auth', app.config.globalProperties.auth);

app.use(router);


app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
