import {  WaveformOctree  } from './WaveformOctree.js';

import * as momentTemp from '../util/moment.js';
const moment = momentTemp["default"];

//import { tracks } from 'vue';




Number.prototype.roundTo = function(num) {
    var resto = this%num;
    if (resto <= (num/2)) {
        return this-resto;
    } else {
        return this+num-resto;
    }
}

function prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
}



export const WaveformPlayer = function ()
{
    var snapTimeout;
    var IsDragging = false;
    var analyser;
    var source;
    var audio;
    var javascriptNode;
    var updateInterval;

    var oldX;
    var mouseX;
    var mouseY;

    var stylePaddingLeft, stylePaddingTop, styleBorderLeft, styleBorderTop;

    var isPlaying = true;

    var playIndex = 1;

    var t, previousTime;
    var elapsed = 200;
    var drawLoad = 1;
    var slowCount = 0;
    var maxSlow = 10;

    var slowComputerDetected = false;
    var slowComputerDetectedCount = 0;

    var newPlayCurrentTime = null;
    var enableControls = true;

    var dragPauseInterval = null;

    var dragPullSlideVelocity = 0;

    var deltaX;
    var virtualCanvasWidth;

    var animateToTimeLocation = false;
    var animateToTime  = 0;
    var lerpFloatvalue = 0;
    var lerpVelocity = 0.3;

    var fingerprint_start_position = 0;
    var fingerprint_stop_position = 0;
    var FingerprinterActive = false;

    // var Recorder;

    return {

        StartFingerprintSection : function()
        {
            WaveformPlayer.Recorder = new Recorder(WaveformPlayer.source);
            $('.pulsating-circle').css({'display':'block'});
            WaveformPlayer.fingerprint_start_position = WaveformPlayer.audio.currentTime;
            WaveformPlayer.FingerprinterActive = true;

            WaveformPlayer.Recorder && WaveformPlayer.Recorder.record();

            setTimeout(function()
            {
                WaveformPlayer.fingerprint_stop_position = WaveformPlayer.audio.currentTime;
                WaveformPlayer.Recorder && WaveformPlayer.Recorder.stop();
                WaveformPlayer.Recorder && WaveformPlayer.Recorder.exportWAV(function(blob)
                {
                    var fd = new FormData();
                    fd.append('audio', 'audio.wav');
                    fd.append('data', blob);
                    $.ajax({
                        type: 'POST',
                        url: '/recognition-process/upload-audio',
                        data: fd,
                        processData: false,
                        contentType: false
                    }).done(function(data)
                    {
                        $('.pulsating-circle').css({'display':'none'});

                        WaveformPlayer.FingerprinterActive = false;

                        if(data.result.status == "MATCH_FOUND")
                            alert(data.result.result.artist + " , " + data.result.result.title + " , " + data.result.result.track_version );
                        else
                            alert("No match found.")

                        /*
                            data = JSON.parse(data);
                            $('.pulsating-circle').css({'display':'none'});
                            // console.log(data.result);
                            if(data.result.status != 'MATCH_NOT_FOUND')
                                $('.recognize_segment_plugin').find('.result').html('<div class="artist_res" style="margin-right: 15px;"> <img src="/img/artist-icon.png" style="filter: invert(100%);width:32px;float:left;border-bottom-left-radius: 10px;" /> <h3>'+data.result.result.artist+'</h3><h4>'+data.result.result.title+'</h4></div>');
                            else
                                $('.recognize_segment_plugin').find('.result').html('<div class="artist_res" style="margin-right: 15px;"> <img src="/img/artist-icon.png" style="filter: invert(100%);width:32px;float:left;border-bottom-left-radius: 10px;" /> <h3></h3><h4>No Match Found</h4></div>');
                            */
                    });
                });
                WaveformPlayer.Recorder.clear();
            },10000);
        },
        MonoToStereo : function()
        {
            //WaveformPlayer.audio.disconnect();
            //var source = WaveformPlayer.context.createMediaElementSource(WaveformPlayer.audio);
            //WaveformPlayer.source.connect(WaveformPlayer.context.destination);

            var splitter = WaveformPlayer.context.createChannelSplitter(2);
            WaveformPlayer.source.connect(splitter);
            //var merger = WaveformPlayer.context.createChannelMerger(2);

            // Reduce the volume of the left channel only
            // var gainNode = ac.createGain();
            // gainNode.gain.setValueAtTime(0.5, ac.currentTime);
            // splitter.connect(gainNode, 0);

            // Connect the splitter back to the second input of the merger: we
            // effectively swap the channels, here, reversing the stereo image.
            //gainNode.connect(merger, 0, 1);
            splitter.connect(splitter, 0, 0);


            // Because we have used a ChannelMergerNode, we now have a stereo
            // MediaStream we can use to pipe the Web Audio graph to WebRTC,
            // MediaRecorder, etc.
            //WaveformPlayer.source.connect(splitter);
            splitter.connect(WaveformPlayer.source);
            //  WaveformPlayer.audio.channelInterpretation
        },
        highLightTrackBasedOnPlayPosition : function()
        {          
            if( typeof WaveformPlayer.playIndex == 'undefined' )
            {
                WaveformPlayer.playIndex = 0;
                WaveformPlayer.internalInstance.selectedTrack = WaveformPlayer.internalInstance.tracks[WaveformPlayer.playIndex];
            }

            var currentSec = parseInt( WaveformPlayer.audio.currentTime );
            
            WaveformPlayer.internalInstance.selectedTrack = WaveformPlayer.internalInstance.tracks[playIndex];

            let nextTrack = WaveformPlayer.internalInstance.tracks[playIndex+1];

          
            var play_time_string = moment($(".entrys").attr("data-recording-start-timestamp"),'YY-MM-DD HH:mm:ss+-HH').add(WaveformPlayer.audio.currentTime, 'seconds');    

            
            var currentTime = moment(WaveformPlayer.internalInstance.selectedTrack.end_time,'YY-MM-DD HH:mm:ss+-HH');
            var nextTime    = moment(nextTrack.start_time,'YY-MM-DD HH:mm:ss+-HH');
            if(nextTime.isSameOrAfter(currentTime))
            {
                WaveformPlayer.playIndex += 1;
                WaveformPlayer.internalInstance.selectedTrack = WaveformPlayer.internalInstance.tracks[WaveformPlayer.playIndex];
            }

            /*
            
            if( currentSec > start_second &&
                currentSec < end_second )
            {
                $("td").removeClass("selected");
                $(nextRow).find('td').addClass("selected");

                WaveformPlayer.playIndex += 1;

                var artist   = $(nextRow).find('td').eq(1).html();
                var title    = $(nextRow).find('td').eq(2).html();
                var version  = $(nextRow).find('td').eq(3).html();

                $('#track-info #name').html( artist + ' ' + title + ' ' + version );
            }

            var nextRow    = $("tbody > tr:not(.head)").eq( WaveformPlayer.playIndex -2);

            var start_second = $(nextRow).find('.start-second').attr('data-start');
            var end_second   = $(nextRow).find('.end-second').attr('data-end');

            console.error("start_second : " + start_second);
            console.error("end_second : "   + end_second);

            if( currentSec > start_second &&
                currentSec < end_second )
            {
                $("td").removeClass("selected");
                $(nextRow).find('td').addClass("selected");

                WaveformPlayer.playIndex -= 1;

                var artist   = $(nextRow).find('td').eq(1).html();
                var title    = $(nextRow).find('td').eq(2).html();
                var version  = $(nextRow).find('td').eq(3).html();

                $('#track-info #name').html( artist + ' ' + title + ' ' + version );
            }

            // });

            */

            /*
            var currentSec = parseInt(WaveformPlayer.audio.currentTime);

            var rows =  $('tbody tr:not(.head):gt('+WaveformPlayer.playIndex+')');
            console.log('check from row index' + WaveformPlayer.playIndex + 'Found : ' + rows.length );

            $.each(rows, function (index)
            {
                var start_second = $(rows[index]).find('.start-second').attr('data-start-second');
                var end_second   = $(rows[index]).find('.end-second').attr('data-end-second');

                if(currentSec > start_second &&
                   currentSec < end_second)
                {
                   // alert('found');
                    // This row is being played ..
                    $(rows[index]).find('td').css({
                      'background-color': "rgb(160, 255, 183)"} );

                    WaveformPlayer.playIndex = index;

                    return false;
                }
                else
                {
                    if( !$(rows[index]).hasClass('editing-hover') )
                    {
                        color = ($(rows[index]).index() % 2) ? 'white' : '#f9f9f9';
                        $(rows[index]).find('td').css('background-color', color);
                    }
                }

                // console.log(index);
            });
            */
        },
        DisableControls : function(){
            WaveformPlayer.enableControls = false;
        },
        EnableControls : function(){
            WaveformPlayer.enableControls = true;
        },
        togglePause : function()
        {
            if( WaveformPlayer.enableControls )
            {
                isPlaying = !isPlaying;
                (isPlaying == false ? WaveformPlayer.audio.pause() : WaveformPlayer.audio.play());

                if(isPlaying)
                {
                    $(".player-controls bi-play-fill").addClass("bi-pause-fill");
                    $(".player-controls bi-play-fill").removeClass("bi-play-fill");
                }
                else
                {
                    $(".player-controls bi-pause-fill").addClass("bi-play-fill");
                    $(".player-controls bi-play-fill").removeClass("bi-pause-fill");
                }
            }
        },
        FastForward : function ()
        {
            if( WaveformPlayer.enableControls )
            {
                WaveformPlayer.audio.pause();
                if (WaveformPlayer.newPlayCurrentTime == null)
                    WaveformPlayer.newPlayCurrentTime = WaveformPlayer.audio.currentTime;
                WaveformPlayer.newPlayCurrentTime += 5;
            }
        },
        Backwards : function ()
        {
            if( WaveformPlayer.enableControls )
            {
                WaveformPlayer.audio.pause();
                if (WaveformPlayer.newPlayCurrentTime == null)
                    WaveformPlayer.newPlayCurrentTime = WaveformPlayer.audio.currentTime;
                WaveformPlayer.newPlayCurrentTime -= 5;
            }
        },
        InitPlayer : function (internalInstance, parent)
        {
            WaveformPlayer.internalInstance = parent;

            

           // internalInstance.parent.selectedTrack  = internalInstance.parent.tracks[3];


            WaveformPlayer.playIndex = 0;
            //WaveformPlayer.Recorder = new Recorder(WaveformPlayer.source);

           // WaveformPlayer.setPlayPosition(0);


            WaveformPlayer.enableControls = true;
            WaveformPlayer.newPlayCurrentTime = null;

            /*
            if($("#waveform-box").attr("data-waveimage") == "false")
            {
                $.post("/ajax-generate-wave-image/" + process_id, {

                })
                .done(function (data) {
                });
            }
            */

            $( "#positionCuePoint" ).mouseout(function() {
                //  focus++;
                $('#popover632077').css({'opacity':'0'});
            });

            WaveformPlayer.InitFullWaveFormHoverEvent();


            /*
            setInterval(function()
            {
               // WaveformPlayer.highLightTrackBasedOnPlayPosition();
            },5000);
            */

            //  var playTimer = function()
            // {
            // in the example above, assign the result
            /*
            var timeoutHandle = setTimeout(function()
            {
                WaveformPlayer.audio.currentTime = WaveformPlayer.newPlayCurrentTime;
                WaveformPlayer.audio.play();
                WaveformPlayer.newPlayCurrentTime = null;
            },200);
            */

            // };

            $(window).keyup(function(e)
            {
                // WaveformPlayer.audio.pause();
                // WaveformPlayer.audio.currentTime = WaveformPlayer.audio.currentTime - 5;
                // WaveformPlayer.audio.play();

                if (e.keyCode == 73 && WaveformPlayer.enableControls)
                {
                    PlayListView.insertNonameSplitAtCurrentPlayTime();
                }

                if (e.keyCode == 37 && WaveformPlayer.enableControls)
                {
                    clearTimeout(timeoutHandle);
                    timeoutHandle = setTimeout(function(){
                        WaveformPlayer.audio.currentTime = WaveformPlayer.newPlayCurrentTime;

                        WaveformPlayer.audio.play();

                        WaveformPlayer.newPlayCurrentTime = null;
                    },200);

                    WaveformPlayer.Backwards();
                }

                if (e.keyCode == 39 && WaveformPlayer.enableControls)
                {
                    clearTimeout(timeoutHandle);
                    timeoutHandle = setTimeout(function(){
                        WaveformPlayer.audio.currentTime = WaveformPlayer.newPlayCurrentTime;
                        WaveformPlayer.audio.play();
                        WaveformPlayer.newPlayCurrentTime = null;
                    },200);

                    WaveformPlayer.FastForward();
                }
            });

            $('#back_step_play').click(function(e)
            {
                WaveformPlayer.audio.pause();
                WaveformPlayer.audio.currentTime = WaveformPlayer.audio.currentTime - 5;
                WaveformPlayer.audio.play();
            });

            $('#front_step_play').click(function(e)
            {
                WaveformPlayer.audio.pause();
                WaveformPlayer.audio.currentTime = WaveformPlayer.audio.currentTime + 5;
                WaveformPlayer.audio.play();
            });

            // Pause
            $(window).keypress(function(e)
            {

                if (e.keyCode == 32 && WaveformPlayer.enableControls && e.target == document.body)
                {
                    WaveformPlayer.togglePause();
                    e.preventDefault();

                    // event.preventDefault();
                    // return false;
                }
                //left = 37
                //up = 38
            });
            

            WaveformPlayer.move_horizontal = new Image();
            WaveformPlayer.move_horizontal.src = '/img/move_horizontal_black.png';///img/move_horizontal.png';

            WaveformPlayer.artistImg = new Image();
            WaveformPlayer.artistImg.src = '/img/artist-icon-black.png';

            if (typeof AudioContext !== "undefined") {
                WaveformPlayer.context = new AudioContext();
            } else if (typeof webkitAudioContext !== "undefined") {
                WaveformPlayer.context = new webkitAudioContext();
            } else {
                throw new Error('AudioContext not supported. :(');
            }

            WaveformPlayer.audio = new Audio();
            WaveformPlayer.audio.preload = "auto";
            WaveformPlayer.audio.src = "https://service-djsets.s3.eu-west-1.amazonaws.com/Club_Air-Main-20240615-224527.ogg"

            // alert( WaveformPlayer.audio.src );
            WaveformPlayer.audio.controls = true;
            WaveformPlayer.audio.autoplay = true;
            WaveformPlayer.audio.loop = true;
            WaveformPlayer.audio.crossOrigin ="anonymous";
            WaveformPlayer.source = WaveformPlayer.context.createMediaElementSource(WaveformPlayer.audio);
            WaveformPlayer.analyser = WaveformPlayer.context.createAnalyser();

            WaveformPlayer.source.connect(WaveformPlayer.analyser);
            WaveformPlayer.analyser.connect(WaveformPlayer.context.destination);
            WaveformPlayer.analyser.smoothingTimeConstant = 0.3;
            WaveformPlayer.analyser.fftSize = 1024;

            WaveformPlayer.javascriptNode = WaveformPlayer.context.createScriptProcessor(2048, 1, 1);
            WaveformPlayer.javascriptNode.connect(WaveformPlayer.context.destination);
            //WaveformPlayer.javascriptNode.addEventListener("onaudioprocess", WaveformPlayer.drawSpectrumAnalyser, true);
            WaveformPlayer.javascriptNode.onaudioprocess = function(event) {
                WaveformPlayer.drawSpectrumAnalyser();
            };

            //WaveformPlayer.Octree.InitOctree();

            WaveformPlayer.Octree = new WaveformOctree();

            WaveformPlayer.audio.onloadedmetadata = function() {
                WaveformPlayer.Octree.InitOctree(WaveformPlayer.internalInstance, "https://service-djsets.s3.eu-west-1.amazonaws.com/Club_air_test/waveforms/Club_Air-Main-20240615-224527.txt");                  
            };

            requestAnimationFrame(WaveformPlayer.Update);           
          

            $('#positionCuePoint').click(function (e)
            {
                WaveformPlayer.setPlayPosition(e,this);
            });

            var canvas = document.getElementById('waveform-zoomed-time-layer');
            // fixes mouse co-ordinate problems when there's a border or padding
            // see getMouse for more detail
            if (document.defaultView && document.defaultView.getComputedStyle)
            {
                stylePaddingLeft = parseInt(document.defaultView.getComputedStyle(canvas, null)['paddingLeft'], 10)     || 0;
                stylePaddingTop  = parseInt(document.defaultView.getComputedStyle(canvas, null)['paddingTop'], 10)      || 0;
                styleBorderLeft  = parseInt(document.defaultView.getComputedStyle(canvas, null)['borderLeftWidth'], 10) || 0;
                styleBorderTop   = parseInt(document.defaultView.getComputedStyle(canvas, null)['borderTopWidth'], 10)  || 0;
            }

            WaveformPlayer.InitZoomedWaveFormDragEvent();
        },
        addCuePointHoverEvent : function (e)
        {
            //alert("cue hover");
            // console.log('hover hover ');
            // Check Hover
            var canvas = document.getElementById('waveform-zoomed-time-layer');
            WaveformPlayer.getMouse(e);

            WaveformPlayer.virtualCanvasWidth =  ( canvas.width  *  (WaveformPlayer.Octree.total_length / (canvas.width) ) );
            var startDataOffset = (WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) *  WaveformPlayer.virtualCanvasWidth;

            $.each(WaveformPlayer.Octree.nodeIndexesToDraw,function(node_index)
            {
            //    alert(JSON.stringify(WaveformPlayer.Octree.OctreeNodes[node_index].cue_points ));
                $.each(WaveformPlayer.Octree.OctreeNodes[node_index].cue_points,function (index)
                {
                    WaveformPlayer.Octree.OctreeNodes[node_index].cue_points[index]._hover = false;
                  //  alert('startoffset x :' + startDataOffset);
                    var cue_point_x     = WaveformPlayer.Octree.OctreeNodes[node_index].cue_points[index].x ;
                    var cue_point_width = 120;
                    var mouse_x         = (WaveformPlayer.mouseX - 525) + (startDataOffset);

                    // console.log("mX:"+mouse_x+",cX:"+cue_point_x+",cW:"+(cue_point_x + cue_point_width )  +",mY:" + WaveformPlayer.mouseY );

                    if( mouse_x >= cue_point_x &&
                        mouse_x <= (cue_point_x + cue_point_width )
                    && WaveformPlayer.mouseY > 0 && WaveformPlayer.mouseY < 60 )
                    {
                        WaveformPlayer.Octree.OctreeNodes[node_index].cue_points[index]._hover = true;
                        console.log('Hover ');
                    }
                });
            });

        },
        CreateSections : function()
        {
            // For waveform length create all Node's for the sections.
            // and initialize first Node with data from server partial.
        },
        Lerp : function (value1, value2, amount)
        {
            amount = amount < 0 ? 0 : amount;
            amount = amount > 1 ? 1 : amount;
            return value1 + (value2 - value1) * amount;
        },
        Update : function()
        {
            t = performance.now();
            WaveformPlayer.elapsed  = t - WaveformPlayer.Octree.previousTime;
            WaveformPlayer.Octree.previousTime = t;

            var fps = 60;
            setTimeout(function()
            {
                requestAnimationFrame(WaveformPlayer.Update);
            },1000 / fps);

            if(WaveformPlayer.animateToTimeLocation)
            {
                WaveformPlayer.lerpFloatvalue += Math.abs(WaveformPlayer.lerpVelocity * (WaveformPlayer.elapsed/60));
                console.log(WaveformPlayer.audio.currentTime, parseInt(WaveformPlayer.animateToTime), WaveformPlayer.lerpFloatvalue);
                console.log(WaveformPlayer.Lerp(WaveformPlayer.audio.currentTime, WaveformPlayer.animateToTime, WaveformPlayer.lerpFloatvalue));
                WaveformPlayer.audio.currentTime = Math.floor(WaveformPlayer.Lerp(WaveformPlayer.audio.currentTime, parseInt(WaveformPlayer.animateToTime), WaveformPlayer.lerpFloatvalue));


                /*
                if(WaveformPlayer.lerpFloatvalue >= 0.5)
                {
                    WaveformPlayer.lerpVelocity -= 0.1;
                }

                if(WaveformPlayer.lerpVelocity < 0.05)
                    WaveformPlayer.lerpVelocity = 0.05;
                */

                if(WaveformPlayer.lerpFloatvalue >= 1)
                {
                    WaveformPlayer.animateToTimeLocation = false;
                    WaveformPlayer.lerpFloatvalue = 0;
                }
            }


            //console.log("elapsed : " +elapsed + ", " + WaveformPlayer.dragPullSlideVelocity);
            //   setInterval(function()
            //  {
            if( Math.abs(WaveformPlayer.deltaX) > 1 && WaveformPlayer.dragPullSlideVelocity > 0.2)
            {
                if(! WaveformPlayer.audio.isPlaying ) {
                    WaveformPlayer.audio.play();
                    WaveformPlayer.audio.playbackRate = 0;
                }

                if(WaveformPlayer.deltaX <  0)
                {

                    WaveformPlayer.audio.currentTime +=  ( (WaveformPlayer.dragPullSlideVelocity )  ) /  WaveformPlayer.virtualCanvasWidth * WaveformPlayer.audio.duration;
                }
                else
                {
                    WaveformPlayer.audio.currentTime -=    ( (WaveformPlayer.dragPullSlideVelocity ) ) /  WaveformPlayer.virtualCanvasWidth * WaveformPlayer.audio.duration;
                }
                //  WaveformPlayer.newPlayCurrentTime -=  deltaX /  virtualCanvasWidth * WaveformPlayer.audio.duration;
                //  WaveformPlayer.Octree.DrawWaveformPlayer(false,1);
                //   if(WaveformPlayer.dragPullSlideVelocity > 0.5)
                WaveformPlayer.dragPullSlideVelocity -= Math.abs(5 * (WaveformPlayer.elapsed/60));
                //   else
                //    WaveformPlayer.dragPullSlideVelocity -= Math.abs(0.1 * (elapsed/60));
            }
            else {
                WaveformPlayer.audio.playbackRate = 1;
            }
            //   },20)

            if(!WaveformPlayer.IsDragging && WaveformPlayer.elapsed >= 16)
                WaveformPlayer.DrawWaveform(WaveformPlayer.elapsed);

            if(elapsed > 50)
            {
                if(slowComputerDetectedCount >= 10)
                    slowComputerDetected = true;

                slowComputerDetectedCount++;
                //alert("slowComputerDetected");
            }
            else if(elapsed <= 40)
            {
                slowComputerDetected = false;
                slowComputerDetectedCount = 0;
            }
            //  slowCount = 0;
            //  }

            /*
                var fps = 120;
                setTimeout(function(){
                     requestAnimationFrame(WaveformPlayer.Update);
                },1000 / fps);

                WaveformPlayer.DrawWaveform();
            */

           // requestAnimationFrame(WaveformPlayer.Update);
        },
        DrawWaveform   : function (elapsed)
        {
            var hoverTime = WaveformPlayer.mouseX /  document.getElementById('waveform-all').width * WaveformPlayer.audio.duration;

            if(!WaveformPlayer.audio.paused) {
                var play_time_string = moment($(".entrys").attr("data-recording-start-timestamp"),'YY-MM-DD HH:mm:ss').add(WaveformPlayer.audio.currentTime, 'seconds');
               $("#cur").html(play_time_string.format("YYYY-MM-DD HH:mm:ss"));
            }
            //$("#tot").html(moment($(".entrys").attr("data-recording-start-timestamp")).format("YYYY-MM-DD HH:mm:ss"));

            if(document.getElementById('positionCuePoint').getContext)
            {
                var ctx = document.getElementById('positionCuePoint').getContext('2d');
                WaveformPlayer.drawPlayPositionBar(document.getElementById('positionCuePoint'), ctx);
            }

          //  if(!WaveformPlayer.audio.paused)
                WaveformPlayer.Octree.DrawWaveformOctree(slowComputerDetected,elapsed);

            //myChart.data.datasets[0].data.push(1);

            //var hits = WaveformPlayer.audio.currentTime;
            //myChart.data.datasets[0].data.shift();
            //myChart.data.datasets[0].data.push(parseInt(hits));
            //myChart.data.datasets[0].data.push(parseInt(hits));

            // myChart.data.labels.pop();
            //myChart.data.datasets.forEach(function(dataset) {
            //         dataset.data.pop();
            // });
          //  myChart.update();

        },

        IsDarkMode : function()
        {
            let isDark =  $("#waveform-zoomed").hasClass('dark');
            //console.log("isDark" + isDark );
        },
        drawSpectrumAnalyser : function ()
        {
            var ctx = document.getElementById('spectrum').getContext('2d');

            if(!this.IsDarkMode())
            {
                
            }

            ctx.clearRect(0, 0, 1000, 20);
            ctx.fillStyle = 'transparent';
            ctx.fillRect(0, 0,1000, 20);

            var gradient = ctx.createLinearGradient(0,0,0,20);
            gradient.addColorStop(1,'#3ecece');
            gradient.addColorStop(0.75,'#3ecece');
            gradient.addColorStop(0.25,'#3ecece');
            gradient.addColorStop(0.05,'#ff0000');
            gradient.addColorStop(0,'transparent');

            var array =  new Uint8Array(WaveformPlayer.analyser.frequencyBinCount);
            WaveformPlayer.analyser.getByteFrequencyData(array);

            ctx.fillStyle = gradient;

            var totalSec = parseInt( WaveformPlayer.audio.currentTime );//new Date().getTime() / 1000;
            var hours = parseInt( totalSec / 3600 ) % 24;
            var minutes = parseInt( totalSec / 60 ) % 60;
            var seconds = totalSec % 60;
            var result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds  < 10 ? "0" + seconds : seconds);

            // $('#track-info #position #cur').html( result );

            var totalSec = parseInt(WaveformPlayer.audio.duration);

            var hours = parseInt( totalSec / 3600 ) % 24;
            var minutes = parseInt( totalSec / 60 ) % 60;
            var seconds = totalSec % 60;
            var result = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds  < 10 ? "0" + seconds : seconds);

            $('#track-info #position #tot').html( result );

            for ( var i = 0; i < (array.length); i++ )
            {
                var value = array[i];
                ctx.fillRect(i*5,(20)-(value/10),3,300);
            }

        },//end of draw spectrum analyser
        IsNodeInView   : function ()
        {
            /*
                if (Is Node within current viewport)
                    return true
            */
            return false;
        },
        getPlayPositionAtHoverLocation : function(e,obj)
        {
            return; //disabled for now .. 


            $('#popover632077').css({'opacity':'1'});

            //op.value.toggle(event);
            // WaveformPlayer.Octree.vue_instance.$refs.waveform.hoverTrack.value.toggle(); 


            // console.log('hvoer..');
            WaveformPlayer.getMouse(e);
            obj = $('#positionCuePoint');
            var xPos;

            var curleft = 0;
            if (obj.offsetParent) {
                do {
                    curleft += obj.offsetLeft;
                } while (obj = obj.offsetParent);
                xPos = curleft;
            }

            var x = e.pageX;
            var coordinateDisplay = x;

            // console.log('hvoer.. ' + x);

            var ctx = document.getElementById('positionCuePoint').getContext('2d');
            var hoverTime = x /  document.getElementById('waveform-all').width * WaveformPlayer.audio.duration;
            //hoverTime -= 16; // correction

            var rec_Start = $(".entrys").attr("data-recording-start-timestamp").replace(" GMT+0","");
            var rec_Start = $(".entrys").attr("data-recording-start-timestamp").replace("+01","");

            var mydate = new Date( rec_Start );
            mydate.setSeconds( mydate.getSeconds() + hoverTime  );
            var formatdate = moment(mydate).format("YYYY-M-D HH:mm:ss");

            var hours    = parseInt( hoverTime / 3600 ) % 24;
            var minutes  = parseInt( hoverTime / 60   ) % 60;
            var seconds  = parseInt( hoverTime % 60   );
            var result   = (hours < 10 ? ("0" + hours)+'' : hours+'') + ":" + (minutes < 10 ? ("0" + minutes) +'' : minutes + '') + ":" + (seconds  < 10 ? ("0" + seconds)+'' : seconds+'');

            $('#popover632077').css({'left':(WaveformPlayer.mouseX -30)+ 'px'});
            $('#popover632077').find('.popover-content').html(formatdate);
        },
        setPlayPosition : function (e,obj)
        {
            var xPos;

            var curleft = 0;
            if (obj.offsetParent) {
                do {
                    curleft += obj.offsetLeft;
                } while (obj = obj.offsetParent);
                xPos = curleft;
            }

            var x = e.pageX - xPos;


            var coordinateDisplay = x;
            var ctx = document.getElementById('positionCuePoint').getContext('2d');
            WaveformPlayer.drawPlayPositionBar(document.getElementById('positionCuePoint'),ctx , x);

            WaveformPlayer.audio.pause();

            var new_play_position = ((x / $('#positionCuePoint').width()) * WaveformPlayer.audio.duration) ;

            var play_time_string = moment($(".entrys").attr("data-recording-start-timestamp"),'YYYY-MM-DD HH:mm:ss').add(new_play_position, 'seconds');    

            WaveformPlayer.audio.currentTime = new_play_position ;

            var waitTime = 150;
            setTimeout(function () {
                // Resume play if the element if is paused.
                if (WaveformPlayer.audio.paused) {
                    WaveformPlayer.context.resume().then(() => {
                        console.log('Playback resumed successfully');
                        WaveformPlayer.audio.play();
                      });
                }
            }, waitTime);
            // WaveformPlayer.audio.play();

            
          
            $.each (WaveformPlayer.internalInstance.tracks, function(index)
            {
                var start_time  = WaveformPlayer.internalInstance.tracks[index].start_time;
                var end_time    = WaveformPlayer.internalInstance.tracks[index].end_time;   
                
               
                    
            
                var currentTime = moment(start_time,'YYYY-MM-DD HH:mm:ss');
                var nextTime    = moment(end_time,'YYYY-MM-DD HH:mm:ss');

                // alert(play_time_string);
                // console.error(currentTime);
                // console.error(nextTime);

                if(play_time_string.isSameOrAfter(currentTime) && play_time_string.isBefore(nextTime))
                {
                    //alert(" track selected " + index +  " " + play_time_string.format('YYYY-MM-DD HH:mm:ss') +  " between  " + currentTime.format('YYYY-MM-DD HH:mm:ss')  +  " " + nextTime.format('YYYY-MM-DD HH:mm:ss') );
                    WaveformPlayer.playIndex = index;
                    WaveformPlayer.internalInstance.selectedTrack = WaveformPlayer.internalInstance.tracks[WaveformPlayer.playIndex];

                    WaveformPlayer.internalInstance.$toast.add({ severity: 'info', summary: 'Skipped to track ',  group: 'tl',  detail: "Title : " + WaveformPlayer.internalInstance.tracks[WaveformPlayer.playIndex].track_title + "\n Artist :" +  WaveformPlayer.internalInstance.tracks[WaveformPlayer.playIndex].artist_name, life: 2000 });

                   
                    var rows = $(".p-datatable-tbody > tr.p-selectable-row");
                    $(".p-datatable-wrapper").scrollTop( $(rows[index]).position().top - $(".p-datatable-wrapper").height() / 2);
                }

                /*
                if( (new_play_position > (start_time) ) && (new_play_position < (end_time) ) )
                {

                    alert(WaveformPlayer.internalInstance.tracks[index]);
                    //$("tbody > tr:not(.head)").find('td').css('background-color', 'white');
                    // $(rows[index]).find('td').css({
                    //           'background-color': "rgb(160, 255, 183)"
                    //       } );
                    $(window).scrollTop( $(rows[index]).offset().top - ($(window).height()/2) - 100);

                    var artist   = $( rows[index] ).find('td').eq(1).html();
                    var title    = $( rows[index] ).find('td').eq(2).html();
                    var version  = $( rows[index] ).find('td').eq(3).html();

                    if( $(rows[index]).hasClass('no_name')  )
                    {
                        $('#track-info #name').html( "No Name" );
                    }
                    else
                    {
                        $('#track-info #name').html( artist + ' - ' + title + ' - ' + version );
                    }

                    WaveformPlayer.playIndex = index;
                    WaveformPlayer.internalInstance.selectedTrack = WaveformPlayer.internalInstance.tracks[WaveformPlayer.playIndex];
                    $("td").removeClass("selected");
                    $(rows[index]).find('td').addClass("selected");
                }
                    */
            });

        },
        drawPlayPositionBar : function(canvas, ctx, xPos)
        {
            // canvas = document.getElementById('positionCuePoint');
            var ctx = document.getElementById('positionCuePoint').getContext('2d');
            ctx.clearRect(0,0, canvas.width, canvas.height);
            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#3ecece";//"rgba(0,153,0,0.9)";
            var x = (WaveformPlayer.audio.currentTime ) / WaveformPlayer.audio.duration * canvas.width;

            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
            ctx.closePath();

            // Draw a second square
            // ctx.fillStyle="rgba(0,153,0,0.3)";


            //WaveformPlayer.virtualCanvasWidth =  ( canvas.width  *  (WaveformPlayer.Octree.total_length / (canvas.width) ) );
            //  WaveformPlayer.waveFormPartialDataDevider = (WaveformPlayer.virtualCanvasWidth/2) / canvas.width;

            // 50% opacity (globalAlpa tip) 0.0-1.0 range
            //ctx.fillRect( x - (canvas.width / WaveformPlayer.waveFormPartialDataDevider) ,0, canvas.width / WaveformPlayer.waveFormPartialDataDevider, canvas.height);

        },//end draw position bar

        onGoToTrackPlayPosition: function(track)
        {
            var recording_start = moment($(".entrys").attr("data-recording-start-timestamp"),'YY-MM-DD HH:mm:ss');

            var duration = moment.duration( moment(track.start_time,'YY-MM-DD HH:mm:ss').diff(recording_start));
            WaveformPlayer.audio.currentTime = duration.asSeconds();

            //alert(seconds);
        },
        onGoToCuePointClick : function( second, row_index , el )
        {

            WaveformPlayer.context.resume();

            setTimeout(function(){
                if ("activeElement" in document)
                    document.activeElement.blur();
            },200);

            if(!WaveformPlayer.audio.isPlaying )
            {
                WaveformPlayer.audio.play();
            }

            row_index = row_index - 1;
            var rows = $("tbody > tr:not(.head)");

            $("td").removeClass("selected");
            $(rows[row_index]).find('td').addClass("selected");

            var row = $("tbody > tr").eq( row_index );

            var artist   = $( row ).find('td').eq(2).html();
            var title    = $( row ).find('td').eq(3).html();
            var version  = $( row ).find('td').eq(4).html();

            if( $(row).hasClass('no_name')  )
            {
                $('#track-info #name').html( "No Name" );
            }
            else
            {
                $('#track-info #name').html( artist + ' - ' + title + ' - ' + version );
            }

            WaveformPlayer.playIndex = row_index;

            WaveformPlayer.animateToTime = second;
            WaveformPlayer.animateToTimeLocation = true;
            WaveformPlayer.lerpFloatvalue = 0;
            WaveformPlayer.lerpVelocity = 0.3;

            //WaveformPlayer.audio.currentTime = second;
            var current_section =  Math.floor((WaveformPlayer.audio.currentTime  / ( 60 * 10 ) ) ) + 1;
            if ( /* !loadedSection[current_section + 1]  && */ WaveformPlayer.audio.currentTime > current_section * (WaveformPlayer.audio.duration % (60 * 10)) /* && !ajaxLoadingPartial */)
            {
                var music_url = $('#audio_file').attr('data-file');
                var music_encoding = 'ogg';

                ajaxLoadingPartial = true;

                WaveformPlayer.Octree.vue_instance.isLoading = true;


                $.post( "/ajax-read-wave-form-partial", {
                    music_url: music_url, music_encoding: music_encoding, step : current_section + 1 , waveform_txt: WaveformPlayer.waveform_txt
                } )
                    .done(function( data )
                    {
                        if(typeof loadedSection != 'undefined')
                            loadedSection[current_section + 1] = true;
                        // alert(data.data);
                        if( typeof data.data != 'null' && typeof data.data != 'undefined' )
                        {
                            // var before_index = 0;
                            $.each(data.data , function (index)
                            {
                                WaveformPlayer.waveform_data.push(data.data[index]);
                                // WaveformPlayer.waveform_data[before_index] = null;
                                // before_index++;
                            });

                            //  WaveformPlayer.waveform_data.splice( 0, 1024 );
                        }

                        WaveformPlayer.Octree.vue_instance.isLoading = false;
                        // partialDataStep++;
                    });
                //   }
            }
        },
        InitZoomedWaveFormDragEvent : function ()
        {
            var mouseIsDown = false;
            var canvas = document.getElementById('waveform-zoomed-time-layer');
            canvas.addEventListener("mousedown", WaveformPlayer.onZoomedWaveFormClickDragEvent, false);
        },
        InitFullWaveFormHoverEvent : function ()
        {
            var mouseIsDown = false;
            var canvas =  document.getElementById("positionCuePoint");

            //$('#positionCuePoint').bind('mousemove',WaveformPlayer.getPlayPositionAtHoverLocation);
//
            canvas.addEventListener("mousemove", WaveformPlayer.getPlayPositionAtHoverLocation, false);
        },
        onZoomedWaveFormClickDragEvent : function (evt)
        {
            evt.preventDefault();

            WaveformPlayer.context.resume();

            var canvas = document.getElementById('waveform-zoomed-time-layer');
            WaveformPlayer.getMouse(evt);
            canvas.removeEventListener("mousemove", WaveformPlayer.addCuePointHoverEvent, false);

            WaveformPlayer.virtualCanvasWidth =  ( canvas.width  *  (WaveformPlayer.Octree.total_length / (canvas.width) ) );
            var startDataOffset = (WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) *  WaveformPlayer.virtualCanvasWidth;

            // RECT : ==  cuePoint1_begin - startDataOffset ,0, cuePoint1_end, canvas.height - 20;
            // console.log( 'X ' +  WaveformPlayer.mouseX + ' > ' + (CuePoint.left_selectionHandle.x ) + ' ::'
            //            + 'X ' +  WaveformPlayer.mouseX + '  < ' +  ( CuePoint.left_selectionHandle.x + 20 )  );

            var clicked_cue_point = false;

         //   WaveformPlayer.Octree.nodeIndexesToDraw
            if(window.location.href.indexOf("/pms") > -1)
            {
                var pms     = true;
            }
            else
                var pms     = false;


            if(!pms)
            $.each(WaveformPlayer.Octree.nodeIndexesToDraw,function(node_index)
                {
                    node_index = WaveformPlayer.Octree.nodeIndexesToDraw[node_index];
          //  if(typeof WaveformPlayer.Octree.OctreeNodes[node_index].cue_points != "undefined")

                $.each(WaveformPlayer.Octree.OctreeNodes[node_index].cue_points,function (index)
                {
                    console.log('node startoffset x :' + WaveformPlayer.Octree.OctreeNodes[node_index].start_x);
                    var cue_point_x     = WaveformPlayer.Octree.OctreeNodes[node_index].cue_points[index].x-20;

                    var cue_point_width = 40;
                    var mouse_x         = (WaveformPlayer.mouseX-(canvas.width/2)) + (startDataOffset);

                    //if(index == 0)
                   // console.log('Nodes to check | ' + node_index + ' | ' + ' is ' + mouse_x + ' inside : ' + cue_point_x + ' > < ' + (cue_point_x + cue_point_width) );
                  // console.log(JSON.stringify(WaveformPlayer.Octree.OctreeNodes[node_index].cue_points))

                    if( mouse_x >= cue_point_x &&
                        mouse_x <= (cue_point_x + cue_point_width )
                        && ( WaveformPlayer.mouseY > (canvas.height-40) && WaveformPlayer.mouseY < canvas.height ) )

                    {
                        clicked_cue_point = true;
                        //selectedCuePointIndex = index;
                        WaveformPlayer.selectedCuePointOctreeNodeIndex = node_index;
                        WaveformPlayer.selectedCuePointIndex = index;

                        WaveformPlayer.selectedCuePointHtmlRowIndex = WaveformPlayer.Octree.OctreeNodes[node_index].cue_point_start_index_count + index;

                        canvas.removeEventListener("mousemove", WaveformPlayer.addCuePointHoverEvent, false);
                        canvas.addEventListener("mousemove", WaveformPlayer.onCuePointDragEvent, false);
                        window.addEventListener("mouseup",   function()
                        {
                            WaveformPlayer.IsDragging = false;
                            var canvas = document.getElementById('waveform-zoomed-time-layer');
                            canvas.removeEventListener("mousemove", WaveformPlayer.onCuePointDragEvent, false);

                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x.roundTo(WaveformPlayer.Octree.secondXWidth);
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;
                

                            //  var currentSec = parseInt(WaveformPlayer.audio.currentTime);
                            //   var nextRow = $("tbody > tr:not(.head)").eq( WaveformPlayer.playIndex );
                            //     var start_second = $(nextRow).find('.start-second').attr('data-start-second');

                            //   var start_second = $("tbody > tr:not(.head)").eq( selectedCuePointIndex ).find('td.start-second').attr('data-start-second');
                            //  WaveformPlayer.onGoToCuePointClick(currentSec,nextRow);
                            //  console.log('start_second' + start_second);
                            //  alert( 'index : ' + selectedCuePointIndex + ": start_second  : " + start_second );

                        }, false);
                    }
                });
            });

            if(!clicked_cue_point)
            {
                canvas.addEventListener("mousemove", WaveformPlayer.onZoomedWaveFormDragEvent, false);
                window.addEventListener("mouseup",   WaveformPlayer.onZoomedWaveFormClickUpEvent, false);
            }
        },
        UpdateDataOnCuepointMovedASecond : function(){

        },
        onCuePointDragEvent : function (evt)
        {
           // clearTimeout(snapTimeout);
         //   snapTimeout = setTimeout(function()
          //  {
                // on mouse up ? 
                //WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x.roundTo(WaveformPlayer.Octree.secondXWidth);
                
                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;
     


            //PlayListView.onPlaylistEdit();
            var canvas = document.getElementById('waveform-zoomed-time-layer');
            var ctx    = canvas.getContext('2d');

            //$('tr[data-index="'+(selectedCuePointHtmlRowIndex+1)+'"]').attr('data-updated','true');
            //$('tr[data-index="'+(selectedCuePointHtmlRowIndex)+'"]').attr('data-updated','true');

            var selectedSelectionHandle;
            WaveformPlayer.getMouse(evt);
            WaveformPlayer.audio.pause();

            //   $.each(WaveformPlayer.Octree.nodeIndexesToDraw,function(node_index)
            //{
                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].left_selectionHandle.x = WaveformPlayer.mouseX;

                var virtualCanvasWidth = (canvas.width * (WaveformPlayer.Octree.total_length / (canvas.width)));

                var deltaX = WaveformPlayer.mouseX - WaveformPlayer.oldX;

              //  console.log("mouseY : " + WaveformPlayer.mouseY);
               // if( WaveformPlayer.mouseY > 0 && WaveformPlayer.mouseY < 40 )
               // {
                    var startDataOffset = (WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) *  WaveformPlayer.virtualCanvasWidth;
                    var newXPosition = ( (startDataOffset ) + WaveformPlayer.mouseX- (canvas.width/2)) - 15;

                    if(newXPosition < 0)
                        newXPosition = 0;

                  //  newXPosition = newXPosition.roundTo(WaveformPlayer.Octree.secondXWidth);

                        WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x = newXPosition;//-= -deltaX;

                    // Only process the rest when moved an entire second
                        //check if This node has the next cuepoint otherwhise check the first index of the next node.
                        if(typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex+1] == "undefined")
                        {
                            var safetyselectedCuePointOctreeNodeIndex = WaveformPlayer.selectedCuePointOctreeNodeIndex+1;
                            for(var x = safetyselectedCuePointOctreeNodeIndex; x < WaveformPlayer.Octree.OctreeNodes.length; x++)
                                if(typeof WaveformPlayer.Octree.OctreeNodes[x].cue_points[0] != "undefined" && !WaveformPlayer.Octree.OctreeNodes[x].cue_points[0].duplicate_other_node_cuepoint) {
                                    safetyselectedCuePointOctreeNodeIndex = x;
                                    break;
                                }

                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].w = (WaveformPlayer.Octree.OctreeNodes[safetyselectedCuePointOctreeNodeIndex].cue_points[0].x - WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x);
                            console.log(WaveformPlayer.Octree.OctreeNodes[safetyselectedCuePointOctreeNodeIndex].cue_points[0].x + "  : " + WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x);
                        }
                        else
                        {
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].w = (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex+1].x - WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x);
                           // alert(WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[selectedCuePointIndex].w);
                        }


                        if(WaveformPlayer.selectedCuePointIndex != 0)
                            if (typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex - 1] == "undefined")
                            {
                                var safetyselectedCuePointOctreeNodeIndex = WaveformPlayer.selectedCuePointOctreeNodeIndex-1;
                                for(var x = safetyselectedCuePointOctreeNodeIndex; x > 0; x--)
                                    if(typeof WaveformPlayer.Octree.OctreeNodes[x].cue_points[0] != "undefined") {
                                        safetyselectedCuePointOctreeNodeIndex = x;
                                       // alert(safetyselectedCuePointOctreeNodeIndex);
                                        break;
                                    }

                                WaveformPlayer.Octree.OctreeNodes[safetyselectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.Octree.OctreeNodes[safetyselectedCuePointOctreeNodeIndex].cue_points.length-1].w = (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].x - WaveformPlayer.Octree.OctreeNodes[safetyselectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.Octree.OctreeNodes[safetyselectedCuePointOctreeNodeIndex].cue_points.length-1].x);
                            }
                            else {
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex - 1].w = (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].x - WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex-1].x);
                            }


                      // Check if this cuepoint is overlapping with another OctreeNode, if it is we need to add the node to the next octree node aswwel, also if there is an overlap both cuepoint (that are actually the same cuepoint should be updated and rendered at the same fucking time...)
                      if( (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x + WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].w)
                      > WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].x + WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].width
                      ) {

                            if(!WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].alreaady_duplicated_other_node_cuepoint) {
                                //alert("This shit is overlapping another node");
                                var to_add = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex];

                                to_add.duplicate_other_node_cuepoint = true;
                                to_add.is_part_of_octree_node = WaveformPlayer.selectedCuePointOctreeNodeIndex;
                                to_add.is_cuepoint_index = WaveformPlayer.selectedCuePointIndex;

                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].alreaady_duplicated_other_node_cuepoint = true;
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points.push(to_add);
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].redrawCuePoints = true;
                               // WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex-1].preRendered = false;
                               // alert("adding fake cuepoint : " + JSON.stringify(WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points));
                                // if cuepoint is in other node entirely then add it to the normal array and remove it from fake cuepoints.
                                //WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points = prepend(to_add, WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points);
                            }
                            else if( typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex +1].fake_cue_points[0] != "undefined") {
                                console.log( " Update the node " );
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points[0].x = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x;
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points[0].w = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].w;


                                if(WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points[0].x >= WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].start_x)
                                {
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points[0].duplicate_other_node_cuepoint = false;
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points[0].alreaady_duplicated_other_node_cuepoint = false;
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points = prepend(WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points[0], WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points);
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points.pop();
                                    //
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_point_start_index_count --;

                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points[0].alreaady_duplicated_other_node_cuepoint = false;
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].fake_cue_points = [];

                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;
                                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;
                                    WaveformPlayer.selectedCuePointIndex = 0;
                                    WaveformPlayer.selectedCuePointOctreeNodeIndex +=1;
                                    //alert("Cue point entirely in next node.");
                                }

                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].redrawCuePoints = true;
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;


                            }
                      }

                    console.log((WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x)
                          + " | "+ WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].start_x
                    );

                    if( (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x)
                        < WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].start_x
                    )
                    {
                        //alert("trying to add fake cuepoint +" + WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[selectedCuePointIndex].alreaady_duplicated_other_node_cuepoint);
                        if( WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points.length == 0 ) { //true | !WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[selectedCuePointIndex].alreaady_duplicated_other_node_cuepoint) {
                            //alert("This shit is overlapping another node");
                            var to_add = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex];

                            to_add.duplicate_other_node_cuepoint = true;
                            to_add.is_part_of_octree_node = WaveformPlayer.selectedCuePointOctreeNodeIndex;
                            to_add.is_cuepoint_index = WaveformPlayer.selectedCuePointIndex;

                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].alreaady_duplicated_other_node_cuepoint = true;
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points.push(to_add);
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].redrawCuePoints = true;
                            alert("adding fake cuepoint Going back a node...? : " + JSON.stringify(WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points));
                            // if cuepoint is in other node entirely then add it to the normal array and remove it from fake cuepoints.
                            //WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points = prepend(to_add, WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].cue_points);
                        }
                        else if( typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points != "undefined") {
                            console.log( " Update the node " );
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points[0].x = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x;
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points[0].w = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].w;

                            if(    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points[0].x + WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points[0].w <= WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].start_x)
                            {
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].cue_points.push(WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points[0]);
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].fake_cue_points = [];

                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex ].redrawCuePoints = true;
                                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex -1 ].redrawCuePoints = true;

                                WaveformPlayer.selectedCuePointOctreeNodeIndex -=1;
                                selectedCuePointIndex = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points.length - 1;
                                //  WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex -1 ].preRendered = false;
                                alert("Cue point entirely in last node.");
                            }

                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].redrawCuePoints = true;
                        }
                    }

            if( WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].w < 200 )
                WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].x = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex +1].x -200;


            //  if( WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].x <  WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex -1].x + 200 )
            //      WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].x = WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex -1].x +200;


              if(typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1] != "undefined")
                        WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex - 1].redrawCuePoints = true;

                    WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex ].redrawCuePoints = true;

                    if(typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1] != "undefined")
                        WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex + 1].redrawCuePoints = true;

                        //change width of previous to match
                        if (typeof WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex - 1] != "undefined")
                            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex - 1].w = (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex ].x - WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex-1].x).roundTo(WaveformPlayer.Octree.secondXWidth);
                        else {
                           // alert("|undefined, cuepoint thingy");
                        }

                        // Setting this cuepoint start_second;
                     
                        var totalSec = (WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].cue_points[WaveformPlayer.selectedCuePointIndex].x.roundTo(WaveformPlayer.Octree.secondXWidth) / virtualCanvasWidth) * WaveformPlayer.audio.duration;
                        totalSec = parseInt(Math.ceil(totalSec)) -1 ;

                        //$("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex).find('td.start-second').attr('data-start', totalSec);

                        //console.log($(".entrys").attr("data-recording-start-timestamp"));
                        
                        var newStartTime = moment($(".entrys").attr("data-recording-start-timestamp"),'YY-MM-DD HH:mm:ss');
                        //console.log( newStartTime.format('YYYY-MM-DD, HH:mm:ss') );

                        newStartTime.add(totalSec,'seconds');

                        WaveformPlayer.Octree.vue_instance.tracks[WaveformPlayer.selectedCuePointHtmlRowIndex].start_time = newStartTime.format('YYYY-MM-DD, HH:mm:ss');

                        if(WaveformPlayer.selectedCuePointHtmlRowIndex-1 > 0)
                            WaveformPlayer.Octree.vue_instance.tracks[WaveformPlayer.selectedCuePointHtmlRowIndex-1].end_time = newStartTime.format('YYYY-MM-DD, HH:mm:ss');


                        WaveformPlayer.Octree.vue_instance.tracks[WaveformPlayer.selectedCuePointHtmlRowIndex].activeStartColor = true;
                        if(WaveformPlayer.selectedCuePointHtmlRowIndex-1 > 0)
                            WaveformPlayer.Octree.vue_instance.tracks[WaveformPlayer.selectedCuePointHtmlRowIndex-1].activeEndColor = true;

                        setTimeout(function(){
                            WaveformPlayer.Octree.vue_instance.tracks[WaveformPlayer.selectedCuePointHtmlRowIndex].activeStartColor = false;
                            if(WaveformPlayer.selectedCuePointHtmlRowIndex-1 > 0)
                                WaveformPlayer.Octree.vue_instance.tracks[WaveformPlayer.selectedCuePointHtmlRowIndex-1].activeEndColor = false;
                        },1000);

                        /*
                        TODO Turned off to see if working 

                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex).find('td.start-second').find('.date-edit-new').val(newStartTime.format('YYYY-MM-DD'));//, h:mm:ss a'));
                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex).find('td.start-second').find('.time-edit-new').val(newStartTime.format('HH:mm:ss'));//, h:mm:ss a'));
                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex).find('td.start-second').attr('data-start', totalSec);

                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex-1).find('td.end-second').attr('data-end', totalSec);
                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex-1).find('td.end-second').find('.date-edit-new').val(newStartTime.format('YYYY-MM-DD'));//, h:mm:ss a'));
                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex-1).find('td.end-second').find('.time-edit-new').val(newStartTime.format('HH:mm:ss'));//, h:mm:ss a'));


                        hours = parseInt(totalSec / 3600) % 24;
                        minutes = parseInt(totalSec / 60) % 60;
                        seconds = totalSec % 60;
                        result = (hours < 10 ? ("0" + hours) + '' : hours + '') + ":" + (minutes < 10 ? ("0" + minutes) + '' : minutes + '') + ":" + (seconds < 10 ? ("0" + seconds) + '' : seconds + '');

                        var newStopTime = moment($(".entrys").attr("data-recording-start-timestamp"));
                        newStopTime.add(totalSec,'seconds');

                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex).find('td.stop-second').find('.date-edit-new').val(newStopTime.format('YYYY-MM-dd'));//, h:mm:ss a'));
                        $("tbody > tr:not(.head)").eq(selectedCuePointHtmlRowIndex).find('td.stop-second').find('.time-edit-new').val(newStopTime.format('HH:mm:ss'));//, h:mm:ss a'));

                        WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;
                        */

                        /*
                        var newTimeHTML = result +
                        '<div class="input-holder">' +
                            '<input class="time-edit" id="hour" data-cuepoint-index="'+(selectedCuePointIndex-1)+'" style="width: 20px;display: inline-block;float: left;" type="text" value="'+ hours +'">'+
                            '<input class="time-edit" id="minute" data-cuepoint-index="'+(selectedCuePointIndex-1)+'" style="width: 20px;display: inline-block;float: left;" type="text" value="'+minutes+'">'+
                            '<input class="time-edit" id="second" data-cuepoint-index="'+(selectedCuePointIndex-1)+'" style="width: 20px;display: inline-block;float: left;"" type="text" value="'+seconds+'">'+
                          '</div>';

                        $('tbody tr[data-index="'+((selectedCuePointIndex-1))+'"]').find('td.end-second').html(newTimeHTML);
                    //      */
                 //   }
          //       }
            //});
            //WaveformPlayer.Octree.OctreeNodes[0].cue_points[selectedCuePointIndex-1].w += (deltaX);
            WaveformPlayer.Octree.cueDragToolTipEnabled = true;

            WaveformPlayer.Octree.OctreeNodes[WaveformPlayer.selectedCuePointOctreeNodeIndex].redrawCuePoints = true;

            WaveformPlayer.IsDragging = true;


            //console.log("Cue drag : " + WaveformPlayer.elapsed );
            //if(WaveformPlayer.elapsed >= 16)
                WaveformPlayer.Octree.DrawWaveformOctree(false,WaveformPlayer.elapsed);
            //else
            //{
              //  console.log("Rendering to fast skipping");
            //}

            // console.log(deltaX);
        },
        onZoomedWaveFormClickDownEvent : function (evt)
        {
            WaveformPlayer.IsDragging = true;
            WaveformPlayer.dragPullSlideVelocity = 0;
            WaveformPlayer.audio.pause();
        },
        onZoomedWaveFormClickUpEvent : function (evt)
        {
            clearTimeout(dragPauseInterval);
            var canvas = document.getElementById('waveform-zoomed-time-layer');
            canvas.removeEventListener("mousemove", WaveformPlayer.onZoomedWaveFormDragEvent, false);
            canvas.removeEventListener("mouseup", WaveformPlayer.onZoomedWaveFormClickUpEvent, false);
            canvas.addEventListener("mousedown", WaveformPlayer.onZoomedWaveFormClickDownEvent, false);

            canvas.addEventListener("mousemove", WaveformPlayer.addCuePointHoverEvent, false);
            if(WaveformPlayer.newPlayCurrentTime != null )
            {
                WaveformPlayer.audio.currentTime = WaveformPlayer.newPlayCurrentTime;
                WaveformPlayer.newPlayCurrentTime = null;

            }

            WaveformPlayer.IsDragging = false;
            WaveformPlayer.audio.playbackRate = 1;
            WaveformPlayer.audio.play();
        },
        onZoomedWaveFormDragEvent : function (evt)
        {
            evt.preventDefault();
            var canvas = document.getElementById('waveform-zoomed-time-layer');
            var virtualCanvasWidth =  ( canvas.width  *  ( WaveformPlayer.Octree.total_length / ( canvas.width ) ) );
            var ctx = canvas.getContext('2d');

            WaveformPlayer.getMouse(evt);
            WaveformPlayer.deltaX =  WaveformPlayer.mouseX - WaveformPlayer.oldX;

            // dragPauseInterval = setTimeout(function(){
            //    WaveformPlayer.audio.pause();
            // },50);

            // if(deltaX != 0)
            //  WaveformPlayer.audio.play();
            WaveformPlayer.audio.playbackRate = 0;
            // else
            WaveformPlayer.audio.pause();

            // console.log(WaveformPlayer.deltaX);
            WaveformPlayer.dragPullSlideVelocity = Math.abs(WaveformPlayer.deltaX) /4;

            //WaveformPlayer.audio.pause();
            if(WaveformPlayer.newPlayCurrentTime == null)
                WaveformPlayer.newPlayCurrentTime = WaveformPlayer.audio.currentTime;

            WaveformPlayer.newPlayCurrentTime -=  WaveformPlayer.deltaX /  virtualCanvasWidth * WaveformPlayer.audio.duration;
            WaveformPlayer.audio.currentTime = WaveformPlayer.newPlayCurrentTime;
            var currentSec = parseInt(WaveformPlayer.audio.currentTime);

            var nextRow = $("tbody > tr:not(.head)").eq( WaveformPlayer.playIndex -3);
            var start_second = $(nextRow).find('.start-second').attr('data-start-second');
            var end_second   = $(nextRow).find('.end-second').attr('data-end-second');

            if(currentSec > start_second &&
                currentSec < end_second)
            {
                $("td").removeClass("selected");
                $(nextRow).find('td').addClass("selected");

                WaveformPlayer.playIndex -=1;
                //return false;
            }

            var nextRow = $("tbody > tr:not(.head)").eq( WaveformPlayer.playIndex -1);
            var start_second = $(nextRow).find('.start-second').attr('data-start-second');
            var end_second   = $(nextRow).find('.end-second').attr('data-end-second');

            if(currentSec > start_second &&
                currentSec < end_second)
            {
                $("td").removeClass("selected");
                $(nextRow).find('td').addClass("selected");

                WaveformPlayer.playIndex +=1;
                //return false;
            }

            
            //if(WaveformPlayer.elapsed >= 16)
                WaveformPlayer.Octree.DrawWaveformOctree(false,WaveformPlayer.elapsed);
            //else
            //{
                //console.log("Rendering to fast skipping");
            //}

        },//
        getMouse : function(e)
        {
            WaveformPlayer.oldX = WaveformPlayer.mouseX;

            var element = document.getElementById('waveform-zoomed-time-layer'), offsetX = 0, offsetY = 0;

            if (element.offsetParent)
            {
                do
                {
                    offsetX += element.offsetLeft;
                    offsetY += element.offsetTop;
                }
                while ((element = element.offsetParent));
            }

            // Add padding and border style widths to offset
            offsetX += stylePaddingLeft;
            offsetY += stylePaddingTop;

            offsetX += styleBorderLeft;
            offsetY += styleBorderTop;


            WaveformPlayer.mouseX = e.pageX - offsetX;
            WaveformPlayer.mouseY = e.pageY - offsetY
        }
    }

}();
