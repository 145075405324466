import { inject } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import locationRoutes from './modules/dmt/page-modules/locations/routes';
import authRoutes from './modules/auth/routes';
import userRoutes from './modules/dmt/page-modules/users/routes';
import chartsRoutes from './modules/dmt/page-modules/charts/routes';
import playlistRoutes from './modules/shared/page-modules/playlist/routes';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...locationRoutes,
        ...authRoutes,
        ...userRoutes,
        ...chartsRoutes,
        ...playlistRoutes,
        // Temporary redirect until main dashboard is here
        {
            path: '/',
            redirect: { path: '/admin/users' },
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: () => import('./modules/shared/page-modules/errors/views/NotFound.vue'),
            meta: { public: true },
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'Forbidden',
            component: () => import('./modules/shared/page-modules/errors/views/Forbidden.vue'),
            meta: { public: true },
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'InternalError',
            component: () => import('./modules/shared/page-modules/errors/views/NotFound.vue'),
            meta: { public: true },
        },
    ],
});

/* eslint-disable consistent-return */
router.beforeEach(async (to) => {
    const auth = inject('auth');

    if (to.meta.public) {
        return true;
    }

    if (to.meta == null || to.meta.action == null) {
        return {
            name: 'InternalError',
            params: { pathMatch: to.path.substring(1).split('/') },
        };
    }

    if (await auth.isLoggedIn() === false) 
    {
        auth.login();    
        return;   
    }    

    if (window.localStorage.getItem('permissions') === null ) 
    {        
        await auth.loadPermissions();
        if(window.localStorage.getItem('permissions') === null) // most likely a anauthorized permission call made
        {
            window.localStorage.clear();
            auth.login(); // redirect to login page.
            return;
        }
    }

    if (auth.hasPermission(to.meta.action)) {
        return true;
    }
    else 
    {
        return {
            name: 'Forbidden',
            params: { pathMatch: to.path.substring(1).split('/') },
        };
    }
   
    // never reached.
    // auth.login();
});

export default router;
