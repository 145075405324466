import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import axios from 'axios';

class Auth {
    constructor() {
        this.manager = new UserManager({
            authority: process.env.VUE_APP_OIDC_AUTHORITY,
            client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
            redirect_uri: process.env.VUE_APP_OIDC_REDIRECT_URL,
            metadata: {
                issuer: process.env.VUE_APP_OIDC_ISSUER,
                token_endpoint: process.env.VUE_APP_OIDC_TOKEN_ENDPOINT,
                authorization_endpoint: process.env.VUE_APP_OIDC_AUTHORIZATION_ENDPOINT,
                userinfo_endpoint: process.env.VUE_APP_OIDC_USERINFO_ENDPOINT,
                end_session_endpoint: process.env.VUE_APP_OIDC_END_SESSION_ENDPOINT,
            },
            stateStore: new WebStorageStateStore({ store: window.localStorage }),
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        });
    }

    async login(redirectUri = null) {
        if (redirectUri == null) {
            /* eslint-disable-next-line no-param-reassign */
            redirectUri = window.location.href;
        }

        await this.manager.signinRedirect({ url_state: redirectUri });
    }

    async logout() {
        window.localStorage.clear();

        await this.manager.signoutRedirect(
            { post_logout_redirect_uri: `${window.location.origin}\\auth\\logout-callback` },
        );
    }

    async isLoggedIn() {
        const user = await this.manager.getUser();
        console.debug(user);

        if (user == null) {
            return false;
        }

        return true;
    }

    async loadPermissions() {
        const accessToken = await this.getAccessToken();

        await axios.get(
            `${process.env.VUE_APP_AUTH_URL}/profile/permissions`,
            { headers: { Authorization: `Bearer ${accessToken}` } },
        ).then((response) => {
            window.localStorage.setItem('permissions', JSON.stringify(response.data.data.permissions));
        }).catch((error) => {
            console.debug(error);
        });
    }

    /* eslint-disable-next-line class-methods-use-this */
    hasPermission(action) {
        const permissions = JSON.parse(window.localStorage.getItem('permissions'));

        if (!permissions) {
            return false;
        }

        if (permissions.find((permission) => permission.action === action)) {
            return true;
        }

        return false;
    }

    async getAccessToken() {
        const user = await this.manager.getUser();

        return user.access_token;
    }
}

export default {
    install: (app) => {
        const auth = new Auth();

        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.auth = auth;
    },
};
