export default [
    {
        path: '/admin/users',
        component: () => import('../../layouts/Dashboard.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/users/List.vue'),
                meta: { action: 'dmt:users:user:list' },
            },
            {
                path: 'new',
                component: () => import('./views/users/Form.vue'),
                meta: { action: 'dmt:users:user:create' },
            },
            {
                path: ':id',
                component: () => import('./views/users/Form.vue'),
                meta: { action: 'dmt:users:user:edit' },
            },
        ],
    },
    {
        path: '/admin/groups',
        component: () => import('../../layouts/Dashboard.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/groups/List.vue'),
                meta: { action: 'dmt:users:group:list' },
            },
            {
                path: 'new',
                component: () => import('./views/groups/Form.vue'),
                meta: { action: 'dmt:users:group:create' },
            },
            {
                path: ':id',
                component: () => import('./views/groups/Form.vue'),
                meta: { action: 'dmt:users:group:edit' },
            },
        ],
    },
];
