export default [
    {
        path: '/locations',
        component: () => import('../../layouts/Dashboard.vue'),
        children: [
            {
                path: '',
                children: [
                    {
                        path: '',
                        component: () => import('./views/locations/List.vue'),
                        meta: { action: 'dmt:locations:location:list' },
                    },
                    {
                        path: 'add',
                        component: () => import('./views/locations/Form.vue'),
                        meta: { action: 'dmt:locations:location:create' },
                    },
                    {
                        path: ':id',
                        component: () => import('./views/locations/View.vue'),
                        meta: { action: 'dmt:locations:location:view' },
                    },
                ],
            },
        ],
    },
    {
        path: '/music-locations',
        component: () => import('../../layouts/Dashboard.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/musicLocations/ListNew.vue'),
                meta: { action: 'dmt:locations:music_location:list' },
            },
            {
                path: 'add',
                component: () => import('./views/musicLocations/Form.vue'),
                meta: { action: 'dmt:locations:music_location:create' },
            },
        ],
    },
];
