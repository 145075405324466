import {  WaveformOctree  } from './WaveformOctree.js';
import {  WaveformPlayer  } from './WaveformPlayer.js';

export class CuePoint
{
    constructor() 
    {
        this.index = "-";
        this.x = 0;
        this.y = 0;
        this.w = 1; // default width and height?
        this.h = 1;
        this.fill = "rgba(153,153,153,0.3)";

        this.no_name = true;

        this.left_selectionHandle;
        this.right_selectionHandle;

        this._hover = false;

    }

    hover(ctx, play_Offset,index_l) 
    {
        console.log("CUeBox Hover");
        // var no_name   = true;
        var cue_color = "rgba(153,153,0,0.1)";

        if(this.no_name)
            cue_color = "rgba(153,0,0,0.1)";

        // We can skip the drawing of elements that have moved off the screen:
        // if (this.x > 1140 || this.y > 160) return;
        // if (this.x + this.w < 0 || this.y + this.h < 0) return;

        // console.log( this.x - play_Offset );

        // play_Offset = 13231 * 0;
        // this.x   = 100;

        /*

        ctx.fillStyle   = 'rgba(69,72,77,0.7)';
        ctx.roundRect( (this.x - play_Offset) - 120 , 50, 320,  75 , 15).fill();
        // ctx.strokeText(  "Artist  : Yves Larock , Track : Push It" ,(this.x - play_Offset) - 110 , 20);

        ctx.fillStyle   = "blue";

        var artist = $("tr[data-index='"+index_l+"'] td:nth-child(2)").html();
        var title  = $("tr[data-index='"+index_l+"'] td:nth-child(3)").html();

        ctx.fillText( artist , (this.x - play_Offset) - 100 , 80);
        ctx.fillText( title  , (this.x - play_Offset) - 100 , 100);
        */
    }

    CuePointInView(startDataOffset,index)
    {
        /*
            if (Is Node within current viewport)
            return true
            */
        var canvas = document.getElementById('waveform-zoomed');

        var offset = startDataOffset + canvas.width /2;

        //var x 	  = offset -  ( (13231) * index );
        //  var width = (13231);

        // console.log(offset + " : "  + this.x ) ;
        //  if(index < 6)
        //	$('.view_array').append( startDataOffset +  '  >  ' + ((13231 * index) - 525)  + '<br/>');


        if( (offset  ) >  (this.x - canvas.width )    &&  ( offset  < ( (this.x + canvas.width)  )  ) )
        {
            return true;
        }
        return false;
    }

        // we used to have a solo draw function
        // but now each box is responsible for its own drawing
        // mainDraw() will call this with the normal canvas
        // myDown will call this with the ghost canvas with 'black'
    Draw( vue_instance, context, play_Offset,index_l , node_index)
    {
        //  if(!this.CuePointInView(play_Offset,index_l))
        //     return;

        var canvas = document.getElementById('waveform-zoomed-time-layer');
        // console.log(index_l);
        // var no_name   = true;
        var cue_color = "rgba(153,153,153,0.1)";

        if(this.no_name)
            cue_color = "rgba(153,0,0,0.1)";

        // We can skip the drawing of elements that have moved off the screen:
        //  if (this.x > 1140 || this.y > 160) return;
        //  if (this.x + this.w < 0 || this.y + this.h < 0) return;

        //  console.log("play_Offset : "  +  play_Offset );

        console.log( parseFloat(this.x) - parseFloat(play_Offset ) );

        //play_Offset = 13231 * 0;

        var x = 0;
        if(index_l == -1)
            x   = parseFloat(this.x) - parseFloat(play_Offset ) ;
        else
            x = parseFloat(this.x) - (node_index * (WaveformOctree._instance.sectionsSeconds * WaveformOctree._instance.secondXWidth) ) ;


        if( index_l == -1)
        {
            cue_color = "rgba(150, 235, 255, 0.7)";

            context.fillStyle = cue_color;
            context.fillRect(x , this.y, this.w, this.h);

            context.strokeStyle = cue_color;
            context.lineWidth = 5;
            // context.strokeRect(x , this.y, this.w, this.h);

            context.fillStyle = 'rgba(69,72,77,0.7)';
            //context.roundRect((this.x ) - 120, 0, 120, 50, 5).fill();
        }



        //context.fillStyle   = "white";
        //context.strokeStyle = "#E6E6E6";

        var canvas_width = $('#waveform-zoomed-time-layer').width();

        var virtualCanvasWidth = ( canvas_width * (WaveformOctree._instance.total_length/(canvas_width) ) );
        // var play_Offset = (WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) * virtualCanvasWidth;

        // 1: Get xmouse location based on -> virtualCanvasWidth;
        // var waveFormPartialDataDevider = virtualCanvasWidth / canvas.width;
        // var virtualMouseXPosition = ( ( ( WaveformPlayer.mouseX + play_Offset ) / virtualCanvasWidth ) * canvas.width ) * waveFormPartialDataDevider;

        // 2: Get ratio based on x_Mouse position.
        // 3: Use ratio to get current time at cursor position.

        // Only needed for rendering at virtual canvas position;
        var totalSec    = ( (x + 5) / virtualCanvasWidth ) * WaveformPlayer.audio.duration;
        totalSec        = parseInt(totalSec );

        // if(totalSec < 0)
        //  continue;

        // $('table tr td#1').attr("data-start-second",totalSec);

        // var totalSec = parseInt( WaveformPlayer.audio.currentTime );
        var hours    = parseInt( totalSec / 3600 ) % 24;
        var minutes  = parseInt( totalSec / 60 ) % 60;
        var seconds  = totalSec % 60;
        var result   = (hours < 10 ? ("0" + hours)+'' : hours+'') + ":" + (minutes < 10 ? ("0" + minutes) +'' : minutes + '') + ":" + (seconds  < 10 ? ("0" + seconds)+'' : seconds+'');

        // $('table tr td#1').html(result);

        if(window.location.href.indexOf("/pms") > -1)
        {
            var pms     = true;
        }
        else
            var pms     = false;

        pms = false;

        context.font = "bold 13px Arial";
        context.strokeWidth = 1;

        if( index_l != -1)
        {
            var artist = vue_instance.tracks[this.index].artist_name;
            var track  = vue_instance.tracks[this.index].track_title;
            var version = $(".entrys").find("tr:not(.head)").eq(this.index ).find("td").eq(4).html();


            if( !vue_instance.tracks[this.index].no_name )
            {
                /* Cuepoint Start Line  */
                context.beginPath();
                context.lineWidth = 5;
                context.strokeStyle = "rgba(160, 255, 183, 0.5)";
                context.moveTo(x  , 0);
                context.lineTo(x , canvas.height);
                context.stroke();
                context.closePath();
                /* Close Cuepoint Start Line  */

                console.log(" Render cuepoint  :  "  + index_l);

                var tX = x;

                if(pms)
                    tX = x-40;

                context.fillStyle   = "black";
                context.fillText(  $("<div/>").html(artist).text(),(tX +100)  , 20);
                context.drawImage(WaveformPlayer.artistImg,tX + 45 , 5, 40, 40);

                context.fillText(  $("<div/>").html(track).text() , (tX +100)  , 40);
                context.fillText( $("<div/>").html(version).text(), (tX +100)  , 60);
            }
            else
            {
                context.beginPath();
                context.lineWidth = 1;
                context.strokeStyle = "rgba(217, 83, 79, 0.5)";
                context.moveTo(x , 0);
                context.lineTo(x , canvas.height);
                context.stroke();
                context.closePath();


                context.fillStyle   = "#ff6e6c";
                context.fillText(   "No Name",(x +10 ) , 20);
            }

        }
        else
        {
            context.fillStyle   = "white";
            context.fillText( "StartFingerprint",(x )  , 20);
        }


        context.strokeWidth = 1;
        //context.strokeText( result , (this.x - play_Offset) - 110 , 40);

        // var x = WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration * canvas.width;

        /*
            WaveformPlayer.waveFormPartialDataDevider = virtualCanvasWidth / canvas.width;
        */

        // draw the boxes
        var half = w / 2;

        context.fillStyle = this.fill;            
        context.fillStyle = "rgba(153,0,0,1)";

        if(typeof(this.left_selectionHandle) == 'undefined')
        { //alert('selection handle undefined ');
            this.left_selectionHandle = new CuePoint;
        }

        this.left_selectionHandle.x = (x - half);
        this.left_selectionHandle.y = this.y  + (10);

        var stop = false;

        if(this._hover) {
            this.hover(context, x, index_l);
        }

        context.lineWidth = 1;

        if( !vue_instance.tracks[this.index].no_name )
            context.fillStyle = "rgba(160, 255, 183, 0.5)";
        else
            context.fillStyle = "rgba(217, 83, 79, 0.5)";


            context.fillRect(x, this.h - context.lineWidth, this.w, 2);
            context.fillRect(x, 0, this.w, 1);

        if(!pms)
        {
            var handle_size = 50;
            context.drawImage(WaveformPlayer.move_horizontal, this.left_selectionHandle.x - (handle_size/2), this.left_selectionHandle.y - 10, handle_size, handle_size);
        }

    } // end draw    
}

CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r)
{
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    this.beginPath();
    this.moveTo(x+r, y);
    this.arcTo(x+w, y,   x+w, y+h, r);
    this.arcTo(x+w, y+h, x,   y+h, r);
    this.arcTo(x,   y+h, x,   y,   r);
    this.arcTo(x,   y,   x+w, y,   r);
    this.closePath();

    return this;
}