<script setup>
    import { ref , onMounted, getCurrentInstance  } from 'vue';
    const count = ref(0);

    const selectedWavefromChannel = ref({ name: "Avg.", value:"mono" });

    const dbfs_range = ref();
    

    const waveform_channels = ref([
    { name: "Avg.", value:"mono" },
    { name: "Left", value:"left" },
    { name: "Right", value:"right" },
    ]);


    import { loadScript } from "vue-plugin-load-script";

    import {  WaveformOctree  } from '../../../../../common-js/waveform/WaveformOctree.js';
    import {  WaveformPlayer  } from '../../../../../common-js/waveform/WaveformPlayer.js';    

    const internalInstance = getCurrentInstance() 

    const onReady = (instance) => 
    { 


        
        loadScript("https://code.jquery.com/jquery-3.7.1.min.js").then(() => 
        {
            //waveform.value.onReady(that);
            WaveformPlayer.InitPlayer(internalInstance, instance);
            $(".p-editable-column").on('DOMSubtreeModified', function()
            {
                $(this).css({"color": "green", "font-weight": "bold"});
                clearTimeout(this.color_timeout);
                this.color_timeout = setTimeout(function(){
                    $(this).css({"color": "black", "font-weight": "normal"});
                },1000);
            } );
        });


    }

    const onChannelChange = (instance) => 
    { 
        WaveformOctree.DestroyOctree();
        WaveformOctree.InitOctree(internalInstance, WaveformOctree.waveform_txt);
    }

    const goToTrack = (track) => 
    {  
        WaveformPlayer.onGoToTrackPlayPosition(track);
    }
    
   
    defineExpose({ onReady , goToTrack, onChannelChange, selectedWavefromChannel, waveform_channels });
</script>

<template>


 <span class="entrys" data-recording-start-timestamp="2024-06-15 22:45:27+02">
 </span>

  <div id="waveform-box" class="header">

<div class="waveform-controls" style="margin-top: 0px;">       

        <div class="panel-body" style="padding : 0px;background:white;">            

            <div class="col-auto">

              <div class="input-group">

                <div class="input-group-prepend" style="width: 35px;position: absolute;">
                  <div class="input-group-text" style="line-height: 2.75;background-color:rgb(62, 206, 206);">L</div>
                </div>

                <img id="waveform-all" class="waveform-image col-11" height="60" style="padding: 0px;display:block; margin-top: 0px; width :100%;" src="https://service-djsets.s3.eu-west-1.amazonaws.com/Club_air_test/waveforms/Club_Air-Main-20240615-224527.png.left"/>     

              </div>

            </div>
              
            <div class="col-auto">
              <div class="input-group">
                <div class="input-group-prepend" style="width: 35px;position: absolute;">
                  <div class="input-group-text" style="line-height: 2.75;background-color:rgb(62, 206, 206);">R</div>
                </div>
                <img id="waveform-all-right" class="waveform-image col-11" height="60" style="padding: 0px;display:block; margin-top: 0px;width :100%;" src="https://service-djsets.s3.eu-west-1.amazonaws.com/Club_air_test/waveforms/Club_Air-Main-20240615-224527.png.right"/>               
              </div>
            </div>        

            <div id="loading_img" style="display:none; width: 100%; height:60px; background-color:white; " >
                Busy generating waveform image.
            </div>

            <canvas id="positionCuePoint" style="position: absolute;  width :100%; height:120px;margin-left: 35px; top: 0px; z-index: 1;cursor: pointer;"></canvas>

            <Popover ref="op">
                <div class="flex flex-col gap-4 w-[25rem]">
                    <div>
                        <span class="font-medium block mb-2">12:00</span>                        
                    </div>
                    
                    <div>
                        <span class="font-medium block mb-2">Track</span>
                        <ul class="list-none p-0 m-0 flex flex-col gap-4">
                            <li v-for="member in members" :key="member.name" class="flex items-center gap-2">
                                <img :src="`https://primefaces.org/cdn/primevue/images/avatar/${member.image}`" style="width: 32px" />
                                <div>
                                    <span class="font-medium">{{ member.name }}</span>
                                    <div class="text-sm text-surface-500 dark:text-surface-400">{{ member.email }}</div>
                                </div>
                                <div class="flex items-center gap-2 text-surface-500 dark:text-surface-400 ml-auto text-sm">
                                    <span>{{ member.role }}</span>
                                    <i class="pi pi-angle-down"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Popover>

        </div>

    </div>

    <!-- Zoomed waveform -->
    <div class="panel panel-default" style="margin-top: 0px;text-align: center;">

        <!--
        <div class="panel-heading-djm" style="padding: 2px;border-bottom: 1pt solid black;border-top: 1pt solid black;">
            <div style="  margin-left: auto;margin-right: auto;">

                <span class="glyphicon glyphicon-headphones" ></span>

                <span style="text-align:center;">WAVEFORM ZOOMED
                
                </span>

                <button style="float:right; cursor:pointer; background: white;height: 22px; padding: 0px;" onclick="javascript:RecreateWaveform('data',true);">
                Recreate Waveform Data
            </button>
            <span style="float:right; text-align:right;"> Normalization Factor : 1.0 
                <span onclikc="javascript:WaveformOctree.normalizationFactor += 0.1">+</span>
                <span onclikc="javascript:WaveformOctree.normalizationFactor -= 0.1">-</span> 
            </span>
            </div>


                <div id="slider2" class="ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all">
                    <div class="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min" style="width: 100%;"></div>
                    <span class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style="left: 100%;"></span>
                </div>


        </div>
         -->
        
        <div class="panel-body" style="padding : 0px; height: 180px ">

           

            <div class="loading_waveform">
                <div style="font-size:28px;color:black;    margin-top: 50px;">
                    Rendering Waveform please wait ...
                </div> 
                </br>
                <ProgressSpinner />
            </div>
            

            <div class="progress progress-striped active loading_waveform" style="display:none;">
                <div class="progress-bar"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;background-color: #5DCA42;">
                    <span class="sr-only">0% Complete</span>
                </div>
            </div>        

            <canvas id="waveform-zoomed" style="display:none;    width: 100%; height: 150px;
                    top: 120px;
                    position: absolute;
                    left: 0; ">
            </canvas>
            
            <canvas id="waveform-zoomed-time-layer"  height="190" style="width: 100%; height: 190px;
                    top: 120px;
                    position: absolute;
                    left: 0;  cursor: pointer;"></canvas>
            <canvas id="spectrum" width="240" height="20" style="position: absolute; right: 0px; top: 280px;" > </canvas>

            <div class="col-auto" style="position: absolute;    top: 120px; left:0px; z-index: 2; cursor: pointer;">
              
              <div class="btn-group btn-toggle" style="float:left;"> 

                <SelectButton v-model="selectedWavefromChannel" :options="waveform_channels" optionLabel="name"  @change="onChannelChange($event)" />                  

             

                <!--
                   <Slider v-model="dbfs_range" range class="h-56" />

                    <button class="btn btn-xs btn-light channel_picker active" data-channel="mono" style="line-height: 0.5; border-radius:0px;">Avg.</button>                    
                    <button class="btn btn-xs btn-light channel_picker" data-channel="left"  style="line-height: 0.5;border-radius:0px;">Left</button>
                    <button class="btn btn-xs btn-light channel_picker" data-channel="right" style="line-height: 0.5;border-radius:0px;" >Right</button>

                <span  id="position" style=" margin-left:50px;" >
                    <span id="cur" style="color:black;">00:00:00</span>
                        /
                    <span id="tot" style="color:black;">00:00:00</span>
                </span>
                -->

              </div>                    

            </div>


        </div>
    </div>
</div>

 <!--
<div class="controls">
    <div class="panel panel-default" style="margin-bottom: 0px;border-bottom: 1pt solid transparent;border-top: 1pt solid transparent;">
        <div class="panel-heading-djm" style="/* padding: 2px; */margin-top: 0px;border-bottom: 1pt solid transparent;border-top: 1pt solid transparent;">

            <span style="float:left;">
                
                <span id="identified">
                    <strong></strong>
                </span>
            </span>

            <div class="player-controls" style="display: inline-block;">
                <i class="col bi bi-skip-backward-fill" style="color:black!important;width: 3em;cursor:pointer;"  onclick="javascript: WaveformPlayer.Backwards()"></i>                 
                <i class="col bi bi-play-fill" style="color:black!important;width: 3em; cursor:pointer;" onclick="javascript: WaveformPlayer.togglePause()"></i>     
                <i class="col bi bi-skip-forward-fill" style="color:black!important;width: 3em;cursor:pointer;" onclick="javascript: WaveformPlayer.FastForward()"></i>
            </div>

            <div class="col-md-8" style="width: 8%; margin-top: -20px; float: right; display: inline-block;">
                <span class="tooltip"></span>
                <div id="slider"></div>
                <span class="volume"></span>
            </div>

            <div class="bs-callout bs-callout-warning" style="display:none;z-index : 10000; right: 200px;  position:absolute; width:320px; height:auto; padding : 0px;margin-top:-1px;">
               <p style="float:left;"> There are unsaved changes made to this playlist</p>
                <button id="save-follow-link" onclick=" javascript: PlayListView.savePlaylist(); $(this).parent().slideUp(); $('#waveform-box').css({ 'top' : '66px' }) " class="btn btn-success navbar-btn pull-right" type="button" style="    border-radius: 0px;width:100%;height: 100%;margin:0px;padding:0px;" url="/event-overview">
                    Save changes
                </button>
            </div>
           
            <div class="recognize_segment_plugin" style="float:right;  margin-right:10px;">
                <img src="/img/fingerprint.png" style="    height: 22px;" />
                <div class="pulsating-circle" style="display:none;"></div>

                <div class="result">
                </div>
            </div>
           

        </div>
    </div>
</div>
 -->
</template>

<style>

    #waveform-box
    {
        transition: left .3s ease;
        z-index:10;  
        position:relative; 
        width:auto; 
        top:0; 
        height: 300px;
    }

    .collapsed #playlist-view #waveform-box
    {
        left: 0px;
    }

    .uncollapsed #playlist-view #waveform-box
    {
        left: 0px;
    }

</style>