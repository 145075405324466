
import {  WaveformOctree  } from './WaveformOctree.js';
import {  WaveformPlayer } from './WaveformPlayer.js';


import * as momentTemp from '../util/moment.js';
const moment = momentTemp["default"];


//Vue.prototype.moment = moment;

export const WaveformOctreeNode = function(octreeInstance) {
    var x;
    var start_x;
    var width;
    var waveform_data      = [];
    var cue_points         = [];
    var fake_cue_points    = [];

    var total_seconds_for_section = 300;//551;

    var start_second       = undefined;
    var end_second         = undefined;

    // when this node is seen fetch the partial data for this node !
    if(window.location.href.indexOf("/pms") > -1) {
        var section_url        = "/pms/ajax-read-wave-form-partial";
    }
    else
        var section_url        = "/ajax-read-wave-form-partial";

    var waveform_txt       = "";

    var isLoaded           = false;

    var sectionStartOffset = 0;
    var node_index         = 0;
    var preRendered        = false;
    var redrawCuePoints    = true;

    var offscreenCanvasNode    = null;
    var nodeimage = null;
    var nodeimageCuePointOverlay = null;

    var cue_point_start_index_count = 0;

    return {

        setCuepoints : function(cue_array,cue_point_start_index_count)
        {
            //console.log('set cuepoints');
            this.cue_points = cue_array;
            this.cue_point_start_index_count = cue_point_start_index_count;
        },
        InitNode : function ()
        {
            this.redrawCuePoints = true;
            this.cue_points = [];
            this.fake_cue_points = [];
        },       

        /*
            This is only used on waveform initialization for the first node,
            at runtime the waveform data is loaded within the node from the section_url
        */
        FillFirstSectionData : function ()
        {
            //  alert("fill data");
            if(!this.isLoaded) {
                alert("filling data");
            }
        },
        DrawPreRenderedNode : function(canvas,ctx,startDataOffset,index,slowPc)
        {
            if( !this.preRendered)
            {
                ctx =  canvas.getContext('2d');
                canvas.width  = parseInt(octreeInstance.section_data_count * octreeInstance.ZoomFactor);
                canvas.height = 150;
                ctx.fillStyle = "transparent";
                ctx.fillRect(0, 0, parseInt(octreeInstance.section_data_count * octreeInstance.ZoomFactor), 150);

                this.offscreenCanvasNode = this.DrawNodeOffscreen(canvas, ctx, startDataOffset, index,slowPc);
            }
            else 
            {
                var viewCanvas = document.getElementById('waveform-zoomed');
                if(viewCanvas.getContext)
                {
                    var ctx    = viewCanvas.getContext('2d');
                    ctx.putImageData(this.offscreenCanvasNode, parseInt(-startDataOffset),0);
                }
            }
        },
        DrawNodeOffscreen : function (canvas,ctx,startDataOffset,index,slowPc)
        {
            if(this.preRendered)
                return;

            this.DrawNode(canvas,ctx,startDataOffset,index,slowPc);
            this.drawZoomedTimeLayer(canvas, ctx,startDataOffset,index);
            //this.nodeimage = ctx.getImageData(0, 0, parseInt(octreeInstance.section_data_count+(octreeInstance.secondXWidth)*10), 150);

            this.preRendered = true;
            return ctx.getImageData(0, 0, parseInt(octreeInstance.section_data_count * octreeInstance.ZoomFactor), 150);
        },

        Normalize: function(OldValue)
        {
            OldValue = 96 - OldValue;

            var NewValue = 0;
            var NewRange = 0;

            NewValue = (Math.log(OldValue)) / Math.log2(1000);

            var OldMin = 56;
            var OldMax = 96;
            
            var NewMin = 0;
            var NewMax = 1;           

            var OldRange = (OldMax - OldMin)
            if (OldRange == 0)
                NewValue = NewMin
            else
            {
                NewRange = (NewMax - NewMin)  
                NewValue = (((OldValue - OldMin) * NewRange) / OldRange) + NewMin
            }

            if(NewValue < 0)
               NewValue = 0;
          
            return NewValue;
        },
        DrawNode : function (canvas,ctx,startDataOffset,index,slowPc)
        {
            // this.width = 10000;
            var colors = ['#09c'];//, '#069', '#09c'];

            ctx.beginPath();
            ctx.lineWidth = 0.5;

            var quality = 1 * octreeInstance.ZoomFactor;
            // if(slowPc)
            //     quality = 3;

            if(this.waveform_data == null)
            {
                octreeInstance.loadNodeData(index);
                return;
            }

            var lastX = 0;
            for(var i = 0; i < this.waveform_data.length; i += ( quality ) )
            {               
                var x =  (i - (0) );

                ctx.lineWidth = 0.5;
                ctx.fillStyle = colors[0];
                ctx.lineWidth = 0.5;

                var y = (canvas.height /2) + ((canvas.height /2) * this.Normalize(this.waveform_data[i]));
                ctx.lineTo(x , Math.ceil(Math.abs(y)) );              

                lastX = x;
            }


            for(var i = this.waveform_data.length ; i > 0; i -= ( quality ) )
            {
                var x =  (i - (0) );               

                ctx.lineWidth = 0.5;
                ctx.fillStyle = colors[0];
                ctx.lineWidth = 0.5;
                var y = (canvas.height /2) - ((canvas.height /2) * this.Normalize(this.waveform_data[i]));
                ctx.lineTo(x , y);

                lastX = x;
            }


            ctx.fillStyle = colors[0];//grd;

            ctx.lineWidth = 0.5;
            ctx.fill();
            ctx.strokeWidth = 2.2;
            ctx.strokeStyle =  "white";
            ctx.stroke();
            // ctx.stroke();
            ctx.closePath();



            
            var start_timestamp_node = moment($(".entrys").attr("data-recording-start-timestamp"),'YYYY-MM-DD HH:mm:ss').add(this.start_second, 'seconds').format("HH:mm:ss");  
            ctx.fillStyle = "black";
            ctx.font = "bolder 21px Arial";
            ctx.fillText( start_timestamp_node, 10   ,  20 );

            //this.drawCuePoints(canvas, ctx, startDataOffset);
            //this.drawZoomedTimeLayer(canvas, ctx,  startDataOffset,index);
        },
        drawZoomedTimeLayer : function(canvas, ctx, startDataOffset,index)
        {
            /* debug octree node section start
            ctx.beginPath();
            ctx.lineWidth = 5;
            ctx.strokeStyle = "green";
            var x = (0);

            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
            ctx.closePath();
            */


            var lastTime = this.start_second;         

            // Draw a time stamp every 10 seconds
            //for(var i = 0; i <= canvas.width; i+= secondXWidth  )
            for(var i = 0; i <= canvas.width; i+= octreeInstance.secondXWidth *10  )
            {
                var x = i;
                
                ctx.beginPath();
                ctx.fillStyle = "#3ecece";
                ctx.lineWidth = 2;
                ctx.strokeStyle = "#3ecece";

                ctx.moveTo(x,canvas.height-15);
                ctx.lineTo(x,canvas.height );
                ctx.stroke();
                

                
                var result = moment($(".entrys").attr("data-recording-start-timestamp"),'YYYY-MM-DD HH:mm:ss').add(lastTime, 'seconds').format("HH:mm:ss");  

                ctx.fillStyle = "#3ecece";
                ctx.font = "bolder 15px Arial";
                ctx.fillText( result, ( x +5 )  ,  canvas.height -10 );
                

                // if(typeof WaveformOctree.HitStatistics != 'undefined')
                //    for(var h = 0 ; h < 10; h++)
                //        this.RenderHitInfoLineChart(canvas, ctx,x,lastTime, startDataOffset, h);

                lastTime += 10;

                //  }
            }
            // }

 
            /* debug octree node section end
            ctx.beginPath();
            ctx.lineWidth = 5;
            ctx.strokeStyle = "red";
            var x = (canvas.width -5);

            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
            ctx.closePath();
            */
            
        },
        // Issue   : When a cuepoint overlaps 2 sections a cuepoint background color will give problems when rendering...

        // Example : The start of the cuepoint is in section 1 and the end of the cuepoint is in section 2.
        //           We want the background of this cuepoint to be RED because it represents a No-Name.
        //           When the current Viewport does not see section 1 it will stop rendering that node,
        //           But when cuepoint start is in section_node 1 and the cuepoint end is in section_node 2
        //           The start of the cuepoint will stop existing so we wont be able to render a RED background from start to end of the cuepoint.

        // Option 1 : Get the total width of the cuepoint from the end of the last section and add that width to the viewport Node check variable,
        //            So that the node will always be rendered when a cuepoint end is still in view from a cuepoint start from a previous section.

        // Pros :   It will fix our issues.

        // Cons :   When a Cuepoint is set from the start of the recording to the end performance will degrade because all nodes will be rendered...

        // Option 2 : Split the Cuepoint so that the moment the section starts a end cuepoint is made,
        //            then at the start of the new section put a invisble cue start ( without a position handler ) and resume to a new cuepoint end.
        //            To simulate a seamless cuepoint.

        // Pros : As Option 1 + performance win for long cuepoints.
        // Cons : Will have to create a fake cuepoint integration.

        // HOW ?
        // A cue_point has a start and a end.

        // 1 : .. When creating a invisble start cuepoint at(in) the start of the new section,
        // At the same time we create a end invisible cuepoint in the previous section.

        // we have a index that we can link to the parent of this invisible cuepoint on the previous section,
        // we also need to add a parent index to the child of this invisible cuepoint.

        // 2 : .. When draggin a cuepoint we do not want to drag or render these invisible start cue_points.
        // .. We need a way to Filter out invisible start cue_points.
        // ..

        // 3 : .. When draggin the end cuepoint back over to its parent section we want to delete the invisible cuepoint that we set for the next section.

        drawCuePoints : function(canvas, ctx,play_Offset, node_index)
        {

            return;
            // var canvas = document.getElementById('waveform-zoomed-time-layer');
            // var ctx = canvas.getContext('2d');
            // var virtualCanvasWidth =  ( canvas.width  *  ( WaveformPlayer.total_length / ( canvas.width ) ) );

            // alert('Virtual CANVAS : ' + virtualCanvasWidth );
            // WaveformPlayer.waveFormPartialDataDevider = virtualCanvasWidth / canvas.width;
            // var play_Offset = (WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) * virtualCanvasWidth;

            var cue_points = this.cue_points;

            if( typeof this.cue_points != "undefined" )
            {
               // console.log(JSON.stringify( this.cue_points));
                $.each( this.cue_points, function (index)
                {
                    //    console.log(JSON.stringify(cue_points));
                    cue_points[index].Draw(octreeInstance.vue_instance,ctx,play_Offset,index+1 , node_index);
                });
            }

            var fake_cue_points = this.fake_cue_points;

            if( typeof fake_cue_points != "undefined" )
            {
                console.log("fake_cue_points: " +JSON.stringify(fake_cue_points));
            //    alert("Fake Cue points + "+ JSON.stringify( this.fake_cue_points));
                $.each( fake_cue_points, function (index)
                {
                        console.log(JSON.stringify(fake_cue_points));
                   // if(typeof this.fake_cue_points != "undefined")
                     //   if(typeof this.fake_cue_points[index] != "undefined")
                    fake_cue_points[index].Draw( octreeInstance.vue_instance, ctx,play_Offset,index+1 , node_index);
                });
            }

            // CuePoint.Draw(ctx, play_Offset,true);
            // CuePoint2.Draw(ctx, play_Offset,false);

            // console.log( 'MouseX : ' + WaveformPlayer.mouseX );
            // ctx.fillStyle = "white";
            // ctx.font = "bold 16px Arial";
            // ctx.fillText( "00h:01m:30s:200ms",  WaveformPlayer.mouseX , 0 );

            /*
                // Draw timelin background
                ctx.fillStyle="rgba(153,153,153,0.3)";
                ctx.fillRect(0 ,canvas.height - 20,canvas.width, 40);

                var virtualCanvasWidth =  ( canvas.width  *  (waveform_data.length/(canvas.width) ) );
                var startDataOffset = (WaveformPlayer.audio.currentTime / WaveformPlayer.audio.duration) * virtualCanvasWidth;

                // Draw a cuepoint Section
                ctx.fillStyle="rgba(153,153,0,0.3)";
                ctx.fillRect( (cuePoint1_begin * secondXWidth) - startDataOffset ,0,((cuePoint1_end) - cuePoint1_begin) * secondXWidth, canvas.height - 20);

                // Draw Drag Points;
                ctx.fillStyle="rgba(255,0,0,1)";
                ctx.fillRect( ( (cuePoint1_begin * secondXWidth)  - startDataOffset) -10 ,(canvas.height/2) -20,20 ,20);
            */
        },

        RenderHitInfoLineChart: function(canvas, ctx,x,lastTime, play_Offset, node_index)
        {
            lastTime = lastTime + node_index;

            if(typeof octreeInstance.HitStatistics[lastTime] == 'undefined')
                return;

            ctx.beginPath();
            ctx.fillStyle = "#fff";
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#fff";



            console.log("HIT STAT : |" + lastTime );

            ctx.fillStyle = "#d8d8d8";
            ctx.font = "bold 16px Arial";
            ctx.fillText( octreeInstance.HitStatistics[lastTime].length + " Hit(s) ", ( x +5 )  ,  100+(20*i) );

            for(var i = node_index; i < octreeInstance.HitStatistics[lastTime].length; i++)
            {
                ctx.moveTo(x+(20*i),canvas.height-30);
                ctx.bezierCurveTo(x+(20*i), 100 + (i*5),x -80,60+(20*i),( x +5 ),15+(20*i));
                ctx.stroke();

                ctx.fillStyle = "#d8d8d8";
                ctx.font = "bold 16px Arial";
                ctx.fillText( octreeInstance.HitStatistics[lastTime][i].artist +" - " + octreeInstance.HitStatistics[lastTime][i].track_title, ( x +5 ),  15+(20*i) );
            }

            // ctx.moveTo(1,canvas.height-30);
            // ctx.lineTo(20,canvas.height );
            // ctx.stroke();
        }
    }
};
