import {  WaveformOctree  } from './WaveformOctree.js';
import {  WaveformOctreeNode  } from './WaveformOctreeNode.js';
import {  WaveformPlayer  } from './WaveformPlayer.js';

import {  CuePoint  } from './CuePoint.js';

import * as momentTemp from '../util/moment.js';
const moment = momentTemp["default"];

export class CuePointRenderer 
{
    constructor() 
    {
        
    }

    Draw(playPos, nodeIndexesToDraw,OctreeNodes,canvastime,context)
    {   
        for ( let node = 0; node < nodeIndexesToDraw.length; node++ ) 
        {
            let node_index     = nodeIndexesToDraw[node];

            for (let cue_index = 0; cue_index < OctreeNodes[node_index].cue_points.length; cue_index++) 
            {
                const cuePoint = OctreeNodes[node_index].cue_points[cue_index];

                //var offset = node_index * (WaveformOctree._instance.sectionsSeconds* WaveformOctree._instance.secondXWidth);
                
                let x = ((cuePoint.x) - playPos) + (canvastime.width /2);
                let end_x = ((cuePoint.end_x) - playPos) + (canvastime.width /2);

                if( x > canvastime.width )
                {
                   continue;
                }

                let fade_out = false;

                if(end_x <= 300 )
                {
                    fade_out = true;
                }

                if(x <= -40 )
                {
                    x = -40;

                    if(fade_out && (cue_index +1) < (OctreeNodes[node_index].cue_points.length))
                    {                        
                        x = (((OctreeNodes[node_index].cue_points[cue_index+1].x) - playPos) + (canvastime.width /2)) - 340;
                    }
                }

                if(end_x <= -40 )
                {
                   continue;
                }
                


                /* Cuepoint Start Line  */
                context.beginPath();
                context.lineWidth = 5;
                context.strokeStyle = "rgba(160, 255, 183, 0.5)";
                context.moveTo(x  , 0);
                context.lineTo(x , canvastime.height);
                context.stroke();
                context.closePath();
                /* Close Cuepoint Start Line  */

                //console.log(" Render cuepoint  :  "  + index_l);


                context.fillStyle   = "black";
                //context.fillText(  $("<div/>").html(artist).text(),(tX +100)  , 20);
                context.drawImage(WaveformPlayer.artistImg,x +45 ,canvastime.height-30, 30, 30);



                var artist = WaveformOctree._instance.vue_instance.tracks[cuePoint.index].artist_name;
                var track = WaveformOctree._instance.vue_instance.tracks[cuePoint.index].track_title;



                context.fillStyle   = "black";

                context.font = "bold 15px 'Inter var'";
                context.fillText(  $("<div/>").html(artist).text(),x + 100  , canvastime.height-20);
                //context.drawImage(WaveformPlayer.artistImg,tX + 45 , 5, 40, 40);

                context.font = "15px 'Inter var'";
                context.fillText(  $("<div/>").html(track).text() , x + 100  , canvastime.height);
                //context.fillText( $("<div/>").html(version).text(), (tX +100)  , 60);


                //context.fillText(  "test track title" , x + 100  , canvastime.height-40);
                //context.fillText( $("<div/>").html(version).text(), (tX +100)  , 60);

                // cuePoint.track_title

                var half = cuePoint.w / 2;

                context.fillStyle = cuePoint.fill;            
                context.fillStyle = "rgba(153,0,0,1)";

                if(typeof(cuePoint.left_selectionHandle) == 'undefined')
                { //alert('selection handle undefined ');
                    cuePoint.left_selectionHandle = new CuePoint;
                }

                cuePoint.left_selectionHandle.x = (cuePoint.x - half);
                cuePoint.left_selectionHandle.y = cuePoint.y  + (10);

                //cuePoint.left_selectionHandle.x = (x);
                //cuePoint.left_selectionHandle.y = 40;

                var stop = false;

                if(cuePoint._hover) {
                    cuePoint.hover(context, x, index_l);
                }

                context.lineWidth = 5;
                context.beginPath();
                context.lineWidth = 5;
                
                if( !WaveformOctree._instance.vue_instance.tracks[cuePoint.index].no_name )
                    context.strokeStyle = "rgba(160, 255, 183, 0.5)";
                else
                    context.strokeStyle = "rgba(217, 83, 79, 0.5)";

                context.moveTo(x, canvastime.height-40);
                context.lineTo(cuePoint.w , canvastime.height-40);
                context.stroke();

                //context.fillRect(x, cuePoint.h - context.lineWidth, cuePoint.w, 2);
                //context.fillRect(x, 0, cuePoint.w, 1);

               // if(!pms)
                //{
                    var handle_size = 50;
                    context.drawImage(WaveformPlayer.move_horizontal, x - (handle_size/2), canvastime.height-40, handle_size, handle_size);
                //}
                
            }
        }

    }
}