export default [
    {
        path: '/charts/custom-chart',
        component: () => import('../../layouts/Dashboard.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/custom-play-chart/View.vue'),
                meta: { action: 'dmt:charts:custom-play-chart:view' },
            }
        ],
    }
];
